import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

const ShirtSizeSelect: React.FC<SelectProps> = (props) => {
    return (
        <div>
            <div className="min-w-[120px]">
                <FormControl fullWidth error={props.error}>
                    <InputLabel id="shirt-size-label" variant="standard">Shirt Size</InputLabel>
                    {/* @ts-ignore */}
                    <Select
                        labelId="shirt-size-label"
                        id="shirt-size-select"
                        label="Shirt Size"
                        variant="standard"
                        {...props}
                    >
                        <MenuItem value="S">Small</MenuItem>
                        <MenuItem value="M">Medium</MenuItem>
                        <MenuItem value="L">Large</MenuItem>
                        <MenuItem value="XL">X-Large</MenuItem>
                        <MenuItem value="XXL">XX-Large</MenuItem>
                        <MenuItem value="DN">I prefer to donate the cost of my shirt</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {props.error &&
                <div className="mt-1 italic text-red text-sm">
                    Please choose a Shirt Size
                </div>
            }
        </div>
    )
};

export default ShirtSizeSelect;
