import React from 'react';
import {Formik, Form} from "formik";
import {object, string, ref} from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import { registerUser } from "../../../redux/login";
import { registeringSelector } from "../../../redux/login/selectors";
import TrackableButton from "../../_common/buttons/trackable";

const RegisterForm: React.FC = () => {
    const registering = useSelector(registeringSelector);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    return (
        <div>
            <div className="text-lg uppercase text-center mb-2 font-hc tracking-wide">
                New User Registration
            </div>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={object().shape({
                    email: string().email("Please enter a valid email").required("Please enter a valid email"),
                    password: string().required("Please enter a password"),
                    confirmPassword: string().oneOf([ref('password'), null], 'Passwords must match')
                })}
                onSubmit={({email, password}) => {
                    // @ts-ignore
                    dispatch(registerUser(email, password, enqueueSnackbar));
                }}>
                <Form>
                    <div className="mb-4">
                        {/* @ts-ignore */}
                        <CustomFormikField
                            name="email"
                            label="Email"
                            placeholder="john.smith@gmail.com"
                            CustomComponent={TextField}
                            variant="standard"
                            fullWidth />
                    </div>
                    <div className="mb-4">
                        {/* @ts-ignore */}
                        <CustomFormikField
                            name="password"
                            label="Password"
                            type="password"
                            CustomComponent={TextField}
                            variant="standard"
                            fullWidth />
                    </div>
                    <div className="mb-4">
                        {/* @ts-ignore */}
                        <CustomFormikField
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            CustomComponent={TextField}
                            variant="standard"
                            fullWidth />
                    </div>
                    {registering ?
                        <div className="flex justify-center">
                            <CircularProgress size={35} />
                        </div> :
                        <>
                            <div className="mt-8 flex justify-center">
                                <TrackableButton eventName="register_form_register_click" variant="contained" color="primary" type="submit" fullWidth>
                                    Register
                                </TrackableButton>
                            </div>

                        </>
                    }
                </Form>
            </Formik>
        </div>
    );
};

export default RegisterForm;