import { createSelector } from "@reduxjs/toolkit";
import { isBefore, parse } from "date-fns";

export const loggedInSelector = ({ login }) => login.loggedIn;
export const loggingInSelector = ({ login }) => login.loggingIn;
export const registeringSelector = ({ login }) => login.registering;
export const claimsSelector = ({ login }) => login.claims;
export const userIdSelector = ({ login }) => login.claims?.user_id;
export const authInitializedSelector = ({ login }) => login.authInitialized;
export const profileLoadingSelector = ({ login }) => login.profileLoading;
export const profileSavingSelector = ({ login }) => login.profileSaving;
export const profileSelector = ({ login }) => login.profile;
export const profileUserIdSelector = ({ login }) => login.profile?.userId;
export const activityLoadingSelector = ({ login }) => login.activityLoading;
export const resetCodeVerifiedSelector = ({ login }) => login.resetCodeVerified;
export const verifyEmailSelector = ({ login }) => login.verifyEmail;
export const loggingActivitySelector = ({ login }) => login.loggingActivity;
export const updatingEmailSelector = ({ login }) => login.updatingEmail;
export const welcomeViewedSelector = ({ login }) => login.welcomeViewed;
export const impersonatingSelector = ({ login }) => login.impersonating;
const activityMapSelector = ({ login }) => login.activityMap;

export const activitySelector = createSelector(
    activityMapSelector,
    (activityMap) => {
        return Object.values(activityMap).sort((v1, v2) => {
            const d1 = parse(v1.date, "yyyy-MM-dd", new Date());
            const d2 = parse(v2.date, "yyyy-MM-dd", new Date());

            // First compare date
            if (isBefore(d1, d2)) {
                return 1;
            } else if (isBefore(d2, d1)) {
                return -1;
            } else {
                // Fallback to id compare if dates match since we don't have timestamps
                if (v1.id > v2.id) {
                    return -1;
                } else if (v2.id > v1.id) {
                    return 1;
                }
                return 0;
            }
        });
    }
);
