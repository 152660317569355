import React from 'react';
import cx from "classnames";

interface Props {
    label: string;
    color: string;
}

const RegistrationLevel: React.FC<Props> = ({ label, color }) => {
    return (
        <span className={cx(`font-hc text-white py-2 px-3 md:px-6`, {
            "bg-gold": color === "gold",
            "bg-silver": color === "silver",
            "bg-bronze": color === "bronze",
            "bg-platinum": color === "platinum",
        })}>
            {label}
        </span>
    )
};

export default RegistrationLevel;