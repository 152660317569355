import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

import {groupsSelector} from "../../../../redux/events/selectors";

interface Props extends SelectProps {
    orgId: string | number;
}

const GroupSelect: React.FC<Props> = ({ orgId, ...otherProps }) => {
    const unfilteredGroups = useSelector(groupsSelector);

    const groups = useMemo(() => {
        return unfilteredGroups?.filter((group: any) => group?.organization_id?.toString() === orgId?.toString())
    }, [unfilteredGroups, orgId]);

    // console.log("orgId", orgId, "groupId", otherProps.value, unfilteredGroups);

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="organization-group-label" variant={otherProps.variant}>Group / Team (Optional)</InputLabel>
                <Select
                    labelId="organization-group-label"
                    id="organization-group-select"
                    label="Group / Team (Optional)"
                    {...otherProps}
                    value={otherProps.value || ""}
                >
                    <MenuItem value="">Select your Group/Team (optional)</MenuItem>
                    {groups && groups.map((group: any) => (
                        <MenuItem key={`group-${group.id}`} value={group.id}>{group.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

export default GroupSelect;