import React from 'react';
import cx from 'classnames';

import LottieAnimation from "../lottie";

interface Props {
    title?: string;
}

const LoadingView: React.FC<Props> = ({ title }) => {
    return (
        <div className="pt-8">
            <div className={cx( "text-lg font-bold text-center", {
                "hidden": !title,
                "-mb-14": title
            })}>
                {title}
            </div>
            <div className="flex justify-center pt-8">
                <LottieAnimation animation="loading" className="w-[250px]" />
            </div>
        </div>
    )
}

export default LoadingView;