import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
        primary: {
            main: '#008cba',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#53c9e8',
            contrastText: '#ffffff',
        },
    },
    typography: {
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(',')
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "700",
                    letterSpacing: "2px"
                },
                // containedPrimary: {
                //     backgroundColor: "#99d1a4"
                // }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundImage: "url('/Color-Bar-Background-Angled-01.jpeg')",
                    backgroundSize: "cover",
                    backgroundPosition: "-10%"
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paperFullScreen: {
                    backgroundColor: "#EEEEEE"
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: "scroll"
                }
            }
        }
    }
});