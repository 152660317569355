import React from 'react';

import api from "../../api";

import awp from '../../img/sponsors/awp.png';
import bcbs from '../../img/sponsors/BCBSTX_Ctr_CMYK_Tagline-Lic-1.jpeg';
import coke from '../../img/sponsors/coke_consolidated_logo_color__primary_.jpeg';
import geico from '../../img/sponsors/geico.png';
import nff from '../../img/sponsors/NFF.png';
import np from '../../img/sponsors/Naturepedic_Logo_COMB_PMS.jpeg';
import pcsi from '../../img/sponsors/Apex-PCSI-FC-TBG-1.png';
import sl from '../../img/sponsors/Silicon-Labs-Logo.png';

const NavSponsors: React.FC = () => {
    return (
        <div className="grid grid-cols-2 gap-6 px-6">
            <div className="flex items-center">
                <a href="https://www.geico.com/military" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_geico_click")}>
                    <img src={geico} alt="Geico Military" className="w-full" />
                </a>
            </div>
            <div>
                <a href="https://www.cokeconsolidated.com" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_coke_click")}>
                    <img src={coke} alt="Coca-Cola Consolidated" className="w-full" />
                </a>
            </div>
            <div>
                <a href="https://www.bcbstx.com/" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_bcbstx_click")}>
                    <img src={bcbs} alt="BlueCross BlueShield of Texas" className="w-full" />
                </a>
            </div>
            <div className="flex justify-center">
                <a href="https://fitness.foundation" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_nff_click")}>
                    <img src={nff} alt="National Fitness Foundation" className="h-[75px] w-auto" />
                </a>
            </div>
            <div>
                <a href="https://www.awpnow.com/" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_awp_click")}>
                    <img src={awp} alt="Alliance Work Partners" className="w-full" />
                </a>
            </div>
            <div>
                <a href="https://www.pcsi.org/" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_pcsi_click")}>
                    <img src={pcsi} alt="PCSI" className="w-full" />
                </a>
            </div>
            <div className="flex items-center">
                <a href="https://www.naturepedic.com/" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_np_click")}>
                    <img src={np} alt="Naturepedic" className="w-full" />
                </a>
            </div>
            <div>
                <a href="https://www.silabs.com/" target="_blank" rel="noopener noreferrer" onClick={() => api.logEvent("sponsor_silabs_click")}>
                    <img src={sl} alt="Silicon Labs" className="w-full" />
                </a>
            </div>
        </div>
    );
};

export default NavSponsors;
