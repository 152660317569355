import { createSlice } from '@reduxjs/toolkit'
import { getLocal, saveLocal } from "../../utils";
import { logout as eventsLogout } from "../events";
import * as Sentry from "@sentry/react";

const KEY_LOGGED_IN = "loggedIn";
const KEY_EMAIL = "authEmail";
const KEY_CLAIMS = "claims";
const KEY_WELCOME = "welcome";
const KEY_IMPERSONATING = "impersonating";

const INITIAL_STATE = {
    authEmail: getLocal(KEY_EMAIL),
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    impersonating: getLocal(KEY_IMPERSONATING, false) === "true" || false,
    loggingIn: false,
    loginError: null,
    claims: getLocal(KEY_CLAIMS, true) || {},
    registering: false,
    driveAuthTokenLoading: false,
    driveAuthToken: null,
    authInitialized: false,
    profile: null,
    profileLoading: true,
    profileSaving: false,
    activityLoading: true,
    activityMap: {},
    resetCodeVerified: false,
    verifyEmail: null,
    loggingActivity: false,
    updatingEmail: false,
    // TODO: Flip this back when we're ready to start showin this
    welcomeViewed: getLocal(KEY_WELCOME, false) === "true" || false,
    // welcomeViewed: true
};

const loginSlice = createSlice({
    name: 'login',
    initialState: INITIAL_STATE,
    reducers: {
        userAuthed(state, action) {
            const { claims } = action.payload;
            saveLocal(KEY_CLAIMS, claims, true);
            saveLocal(KEY_LOGGED_IN, true, false);
            state.claims = claims;
            state.loggingIn = false;
            state.loggedIn = true;
            state.authInitialized = true;
            state.registering = false;
        },
        userUnauthed(state, action) {
            saveLocal(KEY_CLAIMS, {}, true);
            saveLocal(KEY_LOGGED_IN, false, false);
            state.loggedIn = false;
            state.claims = {};
            state.authInitialized = false;
        },
        loggingIn(state, action) {
            state.loggingIn = action.payload;
        },
        registering(state, action) {
            state.registering = action.payload;
        },
        driveAuthTokenLoading(state, action) {
            state.driveAuthTokenLoading = action.payload;
        },
        driveAuthTokenSuccess(state, action) {
            state.driveAuthTokenLoading = false;
            state.driveAuthToken = action.payload;
        },
        loggedIn(state) {
            state.loggedIn = true
        },
        profileLoading(state, action) {
            state.profileLoading = action.payload;
        },
        profileSaving(state, action) {
            state.profileSaving = action.payload;
        },
        activityLoading(state, action) {
            state.activityLoading = action.payload;
        },
        profileSuccess(state, action) {
            state.profileLoading = false;
            state.profileSaving = false;
            state.profile = action.payload;
        },
        activitySuccess(state, action) {
            state.activityLoading = false;
            state.loggingActivity = false;

            // Reset the activityMap if the offset is 0
            if (action.payload.offset === 0) {
                state.activityMap = {}
            }

            action.payload.activity.forEach(activity => {
                state.activityMap[activity.id] = activity;
            });
        },
        resetCodeVerified(state, action) {
            state.resetCodeVerified = true;
            state.verifyEmail = action.payload;
        },
        loggingActivity(state, action) {
            state.loggingActivity = action.payload;
        },
        deleteUserActivity(state, action) {
            delete state.activityMap[action.payload];
        },
        updatingEmail(state, action) {
            state.updatingEmail = action.payload;
        },
        welcomeViewed(state) {
            state.welcomeViewed = true;
            saveLocal(KEY_WELCOME, true, false);
        },
        impersonatingToggle(state, action) {
            state.impersonating = action.payload;
            saveLocal(KEY_IMPERSONATING, action.payload, false);
        }
    }
});

export const {
    userAuthed, userUnauthed, loggingIn, driveAuthTokenLoading, driveAuthTokenSuccess, loggedIn, profileSuccess,
    profileLoading, profileSaving, activityLoading, activitySuccess, registering, resetCodeVerified, loggingActivity,
    deleteUserActivity, updatingEmail, impersonatingToggle, welcomeViewed,
} = loginSlice.actions;

export default loginSlice.reducer

// CUSTOM THUNK ACTIONS

export const signInWithEmailPassword = (email, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.loginWithEmailPassword(email, password)
            .then(() => {
                // Record a user login breadcrumb
                Sentry.addBreadcrumb({
                    category: "auth",
                    message: `Email/pass login: ${email}`,
                    level: "info",
                });
                return true;
            })
            .catch(error => {
                dispatch(legacyAuth(email, password, enqueueSnackbar));
                console.error(error);
            });
    }
);

export const legacyAuth = (email, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.legacyAuth(email, password)
            .then(result => {
                const token = result.data;
                dispatch(signInWithToken(token, () => {}, enqueueSnackbar))
            })
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const signInWithToken = (token, setStatus, enqueueSnackbar, onLogin = () => {}) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.loginWithToken(token)
            .then(credentials => {
                // Record a user login breadcrumb
                Sentry.addBreadcrumb({
                    category: "auth",
                    message: `Token login: ${credentials?.user?.email}`,
                    level: "info",
                });
                dispatch(loggedIn());
                onLogin();
            })
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const signOut = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.logout()
            .then(() => {
                localStorage.clear();
                dispatch(eventsLogout())
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const initiatePasswordReset = (email, showConfirmation, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.initiatePasswordReset(email)
            .then(() => {
                dispatch(loggingIn(false));
                showConfirmation();
            })
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const authenticateDrive = (enqueueSnackbar, callback) => (
    (dispatch, getState, { api }) => {
        dispatch(driveAuthTokenLoading(true));
        return api.authenticateGoogleDrive()
            .then(token => {
                dispatch(driveAuthTokenSuccess(token));
                if (callback) {
                    callback(token);
                }
            })
            .catch(err => {
                dispatch(driveAuthTokenLoading(false));
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const mmfOauth = (setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.mmfOauth()
            .then(response => {
                setLoading(false);
                document.location.href = response.data;
            })
            .catch(err => {
                setLoading(false);
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const garminOauth = (setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.garminOauth()
            .then(response => {
                setLoading(false);
                document.location.href = response.data;
            })
            .catch(err => {
                setLoading(false);
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const stravaOauth = (setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.stravaOauth()
            .then(response => {
                setLoading(false);
                document.location.href = response.data;
            })
            .catch(err => {
                setLoading(false);
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const fitbitOauth = (setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fitbitOauth()
            .then(response => {
                setLoading(false);
                document.location.href = response.data;
            })
            .catch(err => {
                setLoading(false);
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const fetchUserProfile = (userId) => (
    (dispatch, getState, { api }) => {
        dispatch(profileLoading(true));
        return api.watchUserProfile(userId, profile => {
            if (profile.address === null) {
                delete profile.address;
            }
            dispatch(profileSuccess(profile));
        })
            // .then(profile => {
            //     dispatch(profileSuccess(profile));
            // })
            // .catch(err => {
            //     dispatch(profileLoading(false));
            //     enqueueSnackbar(err.message, {variant: 'error'});
            //     console.error(err);
            // });
    }
);

export const updateUserProfile = (updatedProfile, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(profileSaving(true));
        const profile = getState().login.profile;

        return api.updateUserProfile({
            ...profile,
            ...updatedProfile,
        })
            .then(result => {
                dispatch(profileSaving(false));
                enqueueSnackbar("Profile successfully updated", {variant: 'success'})
            })
            .catch(err => {
                dispatch(profileSaving(false));
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const fetchRecentActivity = (offset, pageSize, enqueueSnackbar, loading = true) => (
    (dispatch, getState, { api }) => {
        dispatch(activityLoading(loading));
        return api.fetchRecentActivity(offset, pageSize)
            .then(response => {
                dispatch(activitySuccess({ activity: response.data, offset }));
            })
            .catch(err => {
                dispatch(activityLoading(false));
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const registerUser = (email, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(registering(true));
        return api.registerUser(email, password)
            .then(result => {
                // Record a user register breadcrumb
                Sentry.addBreadcrumb({
                    category: "auth",
                    message: `Registered user: ${email}`,
                    level: "info",
                });
                const token = result.data;
                dispatch(signInWithToken(token, () => {}, enqueueSnackbar));
            })
            .catch(error => {
                dispatch(registering(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const verifyResetCode = (code, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.verifyResetPasswordCode(code)
            .then(email => {
                dispatch(resetCodeVerified(email));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const confirmPasswordReset = (code, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const verifyEmail = getState().login.verifyEmail;
        dispatch(registering(true));
        return api.confirmPasswordReset(code, password)
            .then(email => {
                dispatch(signInWithEmailPassword(verifyEmail, password));
            })
            .catch(error => {
                console.error(error);
                dispatch(registering(false));
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const disconnectFitbit = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const profile = getState().login.profile;
        return api.disconnectFitbit(profile)
            .then(updatedProfile => {
                dispatch(profileSuccess(updatedProfile));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const disconnectMmf = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const profile = getState().login.profile;
        return api.disconnectMmf(profile)
            .then(updatedProfile => {
                dispatch(profileSuccess(updatedProfile));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const disconnectGarmin = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const profile = getState().login.profile;
        return api.disconnectGarmin(profile)
            .then(updatedProfile => {
                dispatch(profileSuccess(updatedProfile));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const disconnectStrava = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const profile = getState().login.profile;
        return api.disconnectStrava(profile)
            .then(updatedProfile => {
                dispatch(profileSuccess(updatedProfile));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const deleteActivity = (activity, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        // Delete it immediately, optimistically
        dispatch(deleteUserActivity(activity.id));

        // Delete from server in background
        return api.deleteActivity(activity.id)
            .then(() => {})
            .catch(error => {
                console.error(error);
                // If this fails we can put the activity back in the store
                dispatch(activitySuccess({activity: [activity], offset: -1}));
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const updateEmail = (email, onComplete, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(updatingEmail(true));
        // Delete from server in background
        return api.updateEmail(email)
            .then(() => {
                dispatch(updatingEmail(false));
                if (onComplete) {
                    onComplete();
                }
                enqueueSnackbar("Email Successfully Updated!", {variant: 'success'});
            })
            .catch(error => {
                console.error(error);
                // If this fails we can put the activity back in the store
                dispatch(updatingEmail(false));
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const impersonateUser = (email, enqueueSnackbar, onComplete = () => {}) => (
    (dispatch, getState, { api }) => {
        return api.impersonateUser(email)
            .then(response => {
                return dispatch(signInWithToken(response.data, () => {}, enqueueSnackbar, () => {
                    onComplete();
                    dispatch(impersonatingToggle(true));
                    window.location.reload();
                }));
            })
            .catch(error => {
                onComplete();
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);
