import React, { useEffect } from 'react';

import HCDialog from "../../_common/dialog";
import {Form, Formik} from "formik";
import CustomFormikField from "../../_common/formik/customField";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import {object, ref, string} from "yup";
import {useDispatch, useSelector} from "react-redux";
import {
    registeringSelector,
    resetCodeVerifiedSelector,
    verifyEmailSelector
} from "../../../redux/login/selectors";
import {useSnackbar} from "notistack";
import {useSearchParams} from "react-router-dom";
import {verifyResetCode, confirmPasswordReset} from "../../../redux/login";
import LoadingView from "../../_common/loading";
import TrackableButton from "../../_common/buttons/trackable";

interface Props {
    open: boolean;
    onClose: () => void;
}

const ResetPasswordDialog: React.FC<Props> = ({ open, onClose}) => {
    const registering = useSelector(registeringSelector);
    const resetCodeVerified = useSelector(resetCodeVerifiedSelector);
    const verifyEmail = useSelector(verifyEmailSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();

    const actionCode = searchParams.get("oobCode");

    // Verify the action code
    useEffect(() => {
        if (actionCode) {
            // @ts-ignore
            dispatch(verifyResetCode(actionCode, enqueueSnackbar))
        }
    }, [actionCode, dispatch, enqueueSnackbar]);

    return (
        <HCDialog
            open={open}
            title={`Reset Password`}
            actions={[
                {label: "Cancel", eventName: "reset_password_dialog_cancel_click", onClick: onClose}
            ]}
            onClose={onClose}>
            {!resetCodeVerified ?
                <LoadingView /> :
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: '',

                    }}
                    validationSchema={object().shape({
                        password: string().required("Please enter a password"),
                        confirmPassword: string().oneOf([ref('password'), null], 'Passwords must match')
                    })}
                    onSubmit={values => {
                        // @ts-ignore
                        dispatch(confirmPasswordReset(actionCode, values.password, enqueueSnackbar));
                    }}
                >
                    {({errors}) => {
                        return (
                            <Form>
                                <div className="w-[400px]">
                                    <div className="text-lg mb-6 text-base">
                                        {verifyEmail}
                                    </div>
                                    <div className="mb-4">
                                        {/* @ts-ignore */}
                                        <CustomFormikField
                                            name="password"
                                            label="Password"
                                            type="password"
                                            CustomComponent={TextField}
                                            variant="standard"
                                            fullWidth />
                                    </div>
                                    <div className="mb-4">
                                        {/* @ts-ignore */}
                                        <CustomFormikField
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type="password"
                                            CustomComponent={TextField}
                                            variant="standard"
                                            fullWidth />
                                    </div>
                                </div>
                                {registering ?
                                    <div className="flex justify-center">
                                        <CircularProgress size={35} />
                                    </div> :
                                    <>
                                        <div className="mt-8 flex justify-center">
                                            <TrackableButton eventName="reset_password_dialog_reset_password_click" variant="contained" color="primary" type="submit" fullWidth>
                                                Reset Password
                                            </TrackableButton>
                                        </div>

                                    </>
                                }
                            </Form>
                        )
                    }}
                </Formik>
            }
        </HCDialog>
    )
};

export default ResetPasswordDialog;