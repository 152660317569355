import { combineReducers } from 'redux'

import events from './events';
import login from './login';

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    events,
    login,
});

export default RootReducer;
