import React, { useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import HCDialog from "../_common/dialog";
import { impersonateUser } from "../../redux/login";

interface Props {
    open: boolean;
    onClose: () => void;
}

const ImpersonateUserDialog: React.FC<Props> = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const submitRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const onImpersonateClick = (data: any) => {
        setLoading(true);
        // @ts-ignore
        dispatch(impersonateUser(data.email, enqueueSnackbar, () => setLoading(false)));
    }

    const localOnClose = () => {
        setLoading(false);
        onClose();
    }

    return (
        <HCDialog
            open={open}
            title="Impersonate User"
            actions={[
                { label: "Cancel", onClick: localOnClose },
                { label: "Impersonate", onClick: () => submitRef.current?.click(), loading },
            ]}
            onClose={localOnClose}>
            <form onSubmit={handleSubmit(onImpersonateClick)} className="min-w-[400px] py-4">
                <input type="Submit" className="hidden" ref={submitRef} />
                <TextField
                    {...register("email", { required: true })}
                    label="Email"
                    type="email"
                    placeholder="steve@healthcode.org"
                    error={Boolean(errors.email)}
                    helperText={`${errors.email?.message || ""}`}
                    fullWidth/>
            </form>
        </HCDialog>
    )
}

export default ImpersonateUserDialog;
