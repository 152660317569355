import React from 'react';
import { useSelector } from "react-redux";
import {
    MilitaryTech,
    DirectionsRun,
    DirectionsBike,
    Pool,
} from "@mui/icons-material";

import {
    eventStatsSelector,
} from "../../../../redux/events/selectors";
import {Tooltip} from "@mui/material";

interface Props {
    eventId: string | number;
}

const EventStandings: React.FC<Props> = ({ eventId }) => {
    const eventStats = useSelector(eventStatsSelector(eventId));

    const overall = eventStats?.users?.rank;
    const walkRun = eventStats?.walkRun?.rank;
    const bikeCycle = eventStats?.bikeCycle?.rank;
    const swimming = eventStats?.swimming?.rank;

    return (
        <div>
            <div>
                <div className="font-bold text-sm uppercase mb-2">
                    My Standings
                </div>
                <div className="flex">
                    <div>
                        <MilitaryTech fontSize="large" className="mr-2" />
                    </div>
                    <div className="flex-1">
                        Overall
                    </div>
                    {overall ?
                        <div className="font-bold text-lg">
                            #{overall}
                        </div> :
                        <Tooltip title="Leaderboards generate every hour, check back next hour for updated results!">
                            <div className="font-bold">--</div>
                        </Tooltip>
                    }
                </div>
                {walkRun &&
                    <div className="flex">
                        <div>
                            <DirectionsRun fontSize="large" className="mr-2"/>
                        </div>
                        <div className="flex-1">
                            Walk / Run
                        </div>
                        <div className="font-bold text-lg">
                            #{walkRun}
                        </div>
                    </div>
                }
                {bikeCycle &&
                    <div className="flex">
                        <div>
                            <DirectionsBike fontSize="large" className="mr-2"/>
                        </div>
                        <div className="flex-1">
                            Bike / Cycle
                        </div>
                        <div className="font-bold text-lg">
                            #{bikeCycle}
                        </div>
                    </div>
                }
                {swimming &&
                    <div className="flex">
                        <div>
                            <Pool fontSize="large" className="mr-2" />
                        </div>
                        <div className="flex-1">
                            Swimming
                        </div>
                        <div className="font-bold text-lg">
                            #{swimming}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default EventStandings;