import { createSlice } from '@reduxjs/toolkit'
import { isAfter, format, subMonths } from 'date-fns';

import { activitySuccess, loggingActivity, deleteUserActivity, fetchRecentActivity } from "../login";

const INITIAL_STATE = {
    loading: true,
    registrationsLoading: false,
    leaderboardsLoading: true,
    eventsMap: {},
    eventsSlugMap: {},
    registrationMap: {},
    currentEventIds: [],
    upcomingEventIds: [],
    leaderboards: {},
    orgLeaderboards: {},
    groupLeaderboards: {},
    bigTickerMap: {},
    eventStatsMap: {},
    eventUserTotalsMap: {},
    orgs: null,
    groups: null,
    registrationUpdating: false,
    ytdStatsByYear: {},
    orgHistoryMap: {},
    submittingFeedback: false,
};

const eventsSlice = createSlice({
    name: 'events',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        leaderboardsLoading(state, action) {
            state.leaderboardsLoading = action.payload;
        },
        eventsSuccess(state, action) {
            const currentSet = new Set(state.currentEventIds);
            const upcomingSet = new Set(state.upcomingEventIds);

            state.loading = false;
            action.payload.forEach(event => {
                state.eventsMap[event.id] = state.eventsSlugMap[event.slug] = {
                    ...event,
                    startDate: event.startDate.toDate(),
                    endDate: event.endDate.toDate(),
                    displayEndDate: event.displayEndDate.toDate(),
                };
                if (isAfter(event.startDate.toDate(), new Date())) {
                    upcomingSet.add(event.id);
                } else {
                    currentSet.add(event.id);
                }
            })

            // action.payload.current?.forEach(event => {
            //     state.eventsMap[event.id] = event;
            //     currentSet.add(event.id);
            // });
            // action.payload.upcoming?.forEach(event => {
            //     state.eventsMap[event.id] = event;
            //     upcomingSet.add(event.id);
            // });

            state.currentEventIds = Array.from(currentSet);
            state.upcomingEventIds = Array.from(upcomingSet);
        },
        eventSuccess(state, action) {
            state.loading = false;
            state.eventsMap[action.payload.id] = state.eventsSlugMap[action.payload.slug] = {
                ...action.payload,
                startDate: action.payload.startDate.toDate(),
                endDate: action.payload.endDate.toDate(),
                displayEndDate: action.payload.displayEndDate.toDate(),
            };
            if (isAfter(action.payload.endDate.toDate(), new Date())) {
                if (isAfter(action.payload.startDate.toDate(), new Date())) {
                    if (!state.upcomingEventIds.includes(action.payload.id)) {
                        state.upcomingEventIds.push(action.payload.id);
                    }
                } else if (!state.currentEventIds.includes(action.payload.id)) {
                    state.currentEventIds.push(action.payload.id);
                }
            }
        },
        registrationsSuccess(state, action) {
            action.payload.forEach(registration => {
                state.registrationMap[registration.eventId] = registration;
            });
            state.registrationUpdating = false;
            state.registrationsLoading = false;
        },
        leaderboardsSuccess(state, action) {
            action.payload.forEach(leaderboard => {
                state.leaderboards[leaderboard.eventId] = leaderboard;
            });
            state.leaderboardsLoading = false;
        },
        leaderboardSuccess(state, action) {
            state.leaderboards[action.payload.eventId] = action.payload;
        },
        groupLeaderboardsSuccess(state, action) {
            state.groupLeaderboards[action.payload.eventId] = {
                ...(state.groupLeaderboards[action.payload.eventId] || {}),
                [action.payload.groupId]: action.payload.items
            }
        },
        orgLeaderboardsSuccess(state, action) {
            state.orgLeaderboards[action.payload.eventId] = {
                ...(state.orgLeaderboards[action.payload.eventId] || {}),
                [action.payload.orgId]: action.payload.items
            }
        },
        bigTickersSuccess(state, action) {
            action.payload.forEach(bigTicker => {
                if (bigTicker) {
                    state.bigTickerMap[bigTicker.event_id] = bigTicker;
                }
            });
        },
        logout(state, action) {
            state.registrationMap = {}
            state.groupLeaderboards = {};
            state.orgLeaderboards = {};
        },
        organizationsSuccess(state, action) {
            state.orgs = action.payload.orgs;
            state.groups = action.payload.groups;
        },
        registrationUpdating(state, action) {
            state.registrationUpdating = action.payload;
        },
        ytdStatsSuccess(state, action) {
            state.ytdStatsByYear[action.payload.year] = action.payload.stats;
        },
        orgHistorySuccess(state, action) {
            state.orgHistoryMap[action.payload.orgId] = action.payload.orgHistory;
        },
        submittingFeedback(state, action) {
            state.submittingFeedback = action.payload;
        },
        eventStatsSuccess(state, action) {
            state.eventStatsMap[action.payload.eventId] = action.payload;
        },
        eventUserTotalsSuccess(state, action) {
            state.eventUserTotalsMap[action.payload.eventId] = action.payload;
        }
    }
});

export const {
    loading, eventsSuccess, registrationsSuccess, leaderboardsSuccess, groupLeaderboardsSuccess, orgLeaderboardsSuccess,
    bigTickersSuccess, eventRegistrationOptionsSuccess, logout, organizationsSuccess, eventSuccess, leaderboardsLoading,
    registrationUpdating, ytdStatsSuccess, orgHistorySuccess, submittingFeedback, eventStatsSuccess, leaderboardSuccess,
    eventUserTotalsSuccess
} = eventsSlice.actions;

export default eventsSlice.reducer

// CUSTOM THUNK ACTIONS

export const fetchEvents = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.fetchEvents()
            .then(events => {
                dispatch(fetchEventLeaderboards(events.map(event => event.id), enqueueSnackbar));
                dispatch(fetchEventBigTickers(events.map(event => event.id), enqueueSnackbar));
                dispatch(eventsSuccess(events));

                // TODO: Re-think this...
                // Fetch all org/group leaderboards for active registrations
                // registrationsResponse.data.forEach(registration => {
                //     if (registration.organization_id) {
                //         dispatch(fetchEventOrganizationLeaderboard(registration.event_id, registration.organization_id, enqueueSnackbar));
                //     }
                //     if (registration.organization_group_id) {
                //         dispatch(fetchEventGroupLeaderboard(registration.event_id, registration.organization_group_id, enqueueSnackbar));
                //     }
                // })
            })
            .catch(error => {
                dispatch(loading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const fetchEvent = (eventId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchEvent(eventId?.toString())
            .then(response => {
                dispatch(eventSuccess(response));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            })
    }
)

export const fetchEventLeaderboards = (eventIds, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return Promise.all(eventIds.filter(eventId => eventId).map(eventId => api.fetchEventLeaderboard(eventId)))
            .then(leaderboards => {
                dispatch(leaderboardsSuccess(leaderboards.filter(leaderboard => leaderboard)));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            })
    }
)

export const fetchEventLeaderboard = (eventId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchEventLeaderboard(eventId)
            .then(response => {
                if (response.data) {
                    dispatch(leaderboardSuccess(response.data));
                }
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            })
    }
)

export const fetchEventBigTickers = (eventIds, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return Promise.all(eventIds.map(eventId => api.fetchEventBigTicker(eventId)))
            .then(bigTickers => {
                dispatch(bigTickersSuccess(bigTickers));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            })
    }
)

export const fetchEventGroupLeaderboard = (eventId, groupId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchEventGroupLeaderboard(eventId, groupId)
            .then(leaderboards => {
                dispatch(groupLeaderboardsSuccess({
                    eventId, groupId, items: leaderboards
                }));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            })
    }
)

export const fetchEventOrganizationLeaderboard = (eventId, organizationId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchEventOrganizationLeaderboard(eventId, organizationId)
            .then(leaderboards => {
                dispatch(orgLeaderboardsSuccess({
                    eventId, orgId: organizationId, items: leaderboards
                }));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
)

export const fetchOrganizations = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchOrganizations()
            .then(response => {
                dispatch(organizationsSuccess(response.data));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
)

export const fetchActiveRegistrations = (userId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        // TODO: Could check logged in status here too
        return api.watchEventRegistrations(userId, registrations => {
            dispatch(registrationsSuccess(registrations));

            const eventIds = registrations.map(registration => registration.eventId);
            // dispatch(fetchEventLeaderboards(eventIds, enqueueSnackbar));
            dispatch(fetchEventBigTickers(eventIds, enqueueSnackbar));

            // Fetch all org/group leaderboards for active registrations
            registrations.forEach(registration => {

                // If we don't have the Event cached, let's download it
                if (!getState().events.eventsMap[registration.eventId]) {
                    dispatch(fetchEvent(registration.eventId, enqueueSnackbar));
                }

                dispatch(fetchEventStats(userId, registration.eventId, registration.orgId, registration.groupId, enqueueSnackbar));
                dispatch(watchEventUserTotals(registration.eventId, userId));

                // if (registration.orgId) {
                //     dispatch(fetchEventOrganizationLeaderboard(registration.eventId, registration.orgId, enqueueSnackbar));
                // }
                // if (registration.groupId) {
                //     dispatch(fetchEventGroupLeaderboard(registration.eventId, registration.groupId, enqueueSnackbar));
                // }
            });
        })
        // return api.fetchActiveRegistrations(getState().login.claims?.user_id)
        //     .then(registrations => {
        //         console.log("registrations", registrations);
        //         dispatch(registrationsSuccess(registrations));
        //
        //         const eventIds = registrations.map(registration => registration.eventId);
        //         dispatch(fetchEventLeaderboards(eventIds, enqueueSnackbar));
        //         dispatch(fetchEventBigTickers(eventIds, enqueueSnackbar));
        //
        //         // Fetch all org/group leaderboards for active registrations
        //         registrations.forEach(registration => {
        //             dispatch(fetchEvent(registration.eventId, enqueueSnackbar));
        //
        //             if (registration.orgId) {
        //                 dispatch(fetchEventOrganizationLeaderboard(registration.eventId, registration.orgId, enqueueSnackbar));
        //             }
        //             if (registration.groupId) {
        //                 dispatch(fetchEventGroupLeaderboard(registration.eventId, registration.groupId, enqueueSnackbar));
        //             }
        //         });
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         enqueueSnackbar(err.message, {variant: 'error'});
        //     });
    }
);

export const fetchPastRegistrations = (userId, enqueueSnackbar) => (
// export const fetchPastRegistrations = (year, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchAllEventRegistrations(userId)
        // return api.fetchPastEventRegistrations(year, getState().login.claims?.user_id)
            .then(registrations => {
                dispatch(registrationsSuccess(registrations));

                const eventIds = registrations.map(registration => registration.eventId);
                dispatch(fetchEventBigTickers(eventIds, enqueueSnackbar));

                // Fetch all org/group leaderboards for active registrations
                registrations.forEach(registration => {

                    // If we don't have the Event cached, let's download it
                    if (!getState().events.eventsMap[registration.eventId]) {
                        dispatch(fetchEvent(registration.eventId, enqueueSnackbar));
                    }

                    dispatch(fetchEventStats(userId, registration.eventId, registration.orgId, registration.groupId, enqueueSnackbar));

                    if (registration.eventEndDate && isAfter(registration.eventEndDate.toDate(), subMonths(new Date(), 1))) {
                        dispatch(watchEventUserTotals(registration.eventId, userId));
                    } else {
                        dispatch(fetchEventUserTotals(registration.eventId, userId, enqueueSnackbar));
                    }

                    // if (registration.orgId) {
                    //     dispatch(fetchEventOrganizationLeaderboard(registration.eventId, registration.orgId, enqueueSnackbar));
                    // }
                    // if (registration.groupId) {
                    //     dispatch(fetchEventGroupLeaderboard(registration.eventId, registration.groupId, enqueueSnackbar));
                    // }
                });
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
        // return api.fetchActiveRegistrations(getState().login.claims?.user_id)
        //     .then(registrations => {
        //         console.log("registrations", registrations);
        //         dispatch(registrationsSuccess(registrations));
        //
        //         const eventIds = registrations.map(registration => registration.eventId);
        //         dispatch(fetchEventLeaderboards(eventIds, enqueueSnackbar));
        //         dispatch(fetchEventBigTickers(eventIds, enqueueSnackbar));
        //
        //         // Fetch all org/group leaderboards for active registrations
        //         registrations.forEach(registration => {
        //             dispatch(fetchEvent(registration.eventId, enqueueSnackbar));
        //
        //             if (registration.orgId) {
        //                 dispatch(fetchEventOrganizationLeaderboard(registration.eventId, registration.orgId, enqueueSnackbar));
        //             }
        //             if (registration.groupId) {
        //                 dispatch(fetchEventGroupLeaderboard(registration.eventId, registration.groupId, enqueueSnackbar));
        //             }
        //         });
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         enqueueSnackbar(err.message, {variant: 'error'});
        //     });
    }
);

export const checkout = (eventId, amount, token, levelId, levelName, levelColor, mileGoal, orgId, groupId, groupAccount, shirtSize, enqueueSnackbar, onComplete) => (
    (dispatch, getState, { api }) => {
        return api.checkout(eventId, amount, token, levelId, levelName, levelColor, mileGoal, orgId, groupId, groupAccount, shirtSize)
            .then(() => {
                // dispatch(fetchActiveRegistrations());
                if (onComplete) {
                    onComplete(true);
                }
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                if (onComplete) {
                    onComplete(false);
                }
            });
    }
)

export const updateEventRegistration = (info, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(registrationUpdating(true));

        const registration = getState().events.registrationMap[info.eventId];
        const { mileGoal, orgId, groupId, groupAccount } = info;

        const updatedReg = {
            ...registration,
            mileGoal,
            orgId,
            groupId,
            groupAccount,
        };

        return api.updateEventRegistration(updatedReg)
            .then(() => {
                dispatch(registrationsSuccess([updatedReg]));
                enqueueSnackbar("Registration successfully updated", {variant: 'success'});
            })
            .catch(err => {
                dispatch(registrationUpdating(false));
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const logActivity = (info, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const profile = getState().login.profile;
        dispatch(loggingActivity(true));

        const { date, activityTypeId, miles, minutes } = info;
        const formattedDate = format(new Date(date), "yyyy-MM-dd");

        // TODO: This would be a lot "safer" if we generated a uuid
        const tmpId = new Date().getTime();
        // Dispatch the local activity immediately
        dispatch(activitySuccess({
            activity: [{
                id: tmpId,
                date: formattedDate,
                activity_type_id: activityTypeId,
                miles: miles ? parseFloat(miles) : 0,
                minutes: minutes ? parseFloat(minutes) : 0,
                local: true,
                loading: true,
            }],
            offset: -1
        }));

        return api.logActivity(
            profile.id,
            formattedDate,
            activityTypeId,
            miles,
            minutes
        )
            .then(() => {
                dispatch(activitySuccess({
                    activity: [{
                        id: tmpId,
                        date: formattedDate,
                        activity_type_id: activityTypeId,
                        miles: miles ? parseFloat(miles) : 0,
                        minutes: minutes ? parseFloat(minutes) : 0,
                        local: true,
                    }],
                    offset: -1
                }));
                // TODO: Figure out a better way to update this data
                // dispatch(fetchActiveRegistrations(enqueueSnackbar));
                dispatch(fetchRecentActivity(0, 50, enqueueSnackbar, false));
                enqueueSnackbar("Activity successfully saved", {variant: 'success'});
            })
            .catch(err => {
                dispatch(loggingActivity(false));
                dispatch(deleteUserActivity(tmpId))
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const fetchYtdStats = (userId, year, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchYtdStats(userId, year)
            .then(stats => {
                dispatch(ytdStatsSuccess({ year, stats }));
            })
            .catch(err => {
                // dispatch(loggingActivity(false));
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const exportOrgLeaderboard = (eventId, orgId, setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.exportOrgLeaderboard(eventId, orgId)
            .then(response => {
                window.open(response.data);
                setLoading(false);
            })
            .catch(err => {
                // dispatch(loggingActivity(false));
                setLoading(false);
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const fetchOrgHistory = (orgId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchOrgHistory(orgId)
            .then(orgHistory => {
                dispatch(orgHistorySuccess({ orgId, orgHistory }));
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const submitFeedback = (email, message, onComplete, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(submittingFeedback(true));
        return api.submitFeedback(message, email)
            .then(result => {
                onComplete();
                dispatch(submittingFeedback(false));
                enqueueSnackbar("Feedback submitted.", {variant: 'success'});
            })
            .catch(err => {
                dispatch(submittingFeedback(false));
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const fetchEventStats = (userId, eventId, orgId, groupId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchEventStats(eventId, userId, orgId, groupId)
            .then(stats => {
                dispatch(eventStatsSuccess(stats));
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const watchEventUserTotals = (eventId, userId) => (
    (dispatch, getState, { api }) => {
        return api.watchUserEventStats(eventId, userId, stats => {
            dispatch(eventUserTotalsSuccess({
                ...stats,
                eventId
            }));
        })
    }
);

// TODO: Watch this for live updates
export const fetchEventUserTotals = (eventId, userId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.fetchUserEventStats(eventId, userId)
            .then(stats => {
                dispatch(eventUserTotalsSuccess({
                    ...stats,
                    eventId
                }));
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);
