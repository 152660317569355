import React from 'react';
import numeral from "numeral";

import FireIcon from "@mui/icons-material/LocalFireDepartment";
import MilesIcon from "@mui/icons-material/AddRoad";
import MinutesIcon from "@mui/icons-material/Schedule";
import { Tooltip } from "@mui/material";

interface Props {
    bigTicker: {
        totalCalories: number;
        totalMiles: number;
        totalMinutes: number;
    }
}

const BigTicker: React.FC<Props> = ({ bigTicker }) => {
    return (
        <div className="flex flex-col w-full">
            <div className="flex-1 flex">
                <div className="flex items-center mr-2">
                    <MilesIcon fontSize="large" />
                </div>
                <div className="text-center flex items-center flex-1">
                    <div className="flex-1 text-left">
                        Miles
                    </div>
                    <div className="font-bold">
                        {bigTicker ?
                            numeral(Math.round(bigTicker.totalMiles)).format("0,0") :
                            <Tooltip title="Leaderboards generate every hour, check back next hour for updated results!">
                                <div>--</div>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
            <div className="flex-1 flex">
                <div className="flex items-center mr-2">
                    <MinutesIcon fontSize="large" />
                </div>
                <div className="text-center flex items-center flex-1">
                    <div className="flex-1 text-left">
                        Minutes of Activity
                    </div>
                    <div className="font-bold">
                        {bigTicker ?
                            numeral(Math.round(bigTicker.totalMinutes)).format("0,0") :
                            <Tooltip title="Leaderboards generate every hour, check back next hour for updated results!">
                                <div>--</div>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
            <div className="flex-1 flex">
                <div className="flex items-center mr-2">
                    <FireIcon fontSize="large" />
                </div>
                <div className="text-center flex items-center flex-1">
                    <div className="flex-1 text-left">
                        Calories Burned
                    </div>
                    <div className="font-bold">
                        {bigTicker ?
                            numeral(Math.round(bigTicker.totalCalories)).format("0,0") :
                            <Tooltip title="Leaderboards generate every hour, check back next hour for updated results!">
                                <div>--</div>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BigTicker;