import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import ActivityIcon from "@mui/icons-material/FitnessCenter";
import NotificationIcon from "@mui/icons-material/NotificationImportant";
import {Button, CircularProgress} from "@mui/material";
import cx from "classnames";

import EventRegistration from "./event/register";
import HCDialog from "../_common/dialog";
import LoadingView from "../_common/loading";
import Label from "../_common/text/label";
import EventItem from "./event";
import {
    currentRegisteredEventsSelector,
    currentUnregisteredEventsSelector, eventsLoadingSelector,
    upcomingEventsSelector
} from "../../redux/events/selectors";
import LogActivityDialog from "../activity/logActivityDialog";
import {loggedInSelector, loggingActivitySelector, profileSelector} from "../../redux/login/selectors";
import Leaderboard from "../leaderboards/leaderboard";
import TrackableButton from "../_common/buttons/trackable";

const Dashboard: React.FC = () => {
    const [showLogActivity, setShowLogActivity] = useState(false);
    const currentRegisteredEvents = useSelector(currentRegisteredEventsSelector);
    const currentUnregisteredEvents = useSelector(currentUnregisteredEventsSelector);
    const upcomingEvents = useSelector(upcomingEventsSelector);
    const eventsLoading = useSelector(eventsLoadingSelector);
    const loggingActivity = useSelector(loggingActivitySelector);
    const profile = useSelector(profileSelector);
    const loggedIn = useSelector(loggedInSelector);
    const navigate = useNavigate();
    const { eventId, leaderboardId } = useParams();

    const onRegisterClick = (eventId: string | number) => {
        navigate(eventId.toString());
    }

    const onDismiss = () => {
        // navigate(location.pathname.replace(`${eventId}`, ""))
        navigate("/dashboard");
    }

    const onDismissEventRegistration = () => {
        onDismiss();
        if (!profile?.firstName) {
            window.setTimeout(() => navigate("/profile"), 500);
        }
    }

    return (
        <div>
            {eventsLoading ?
                <LoadingView title="Loading events..." /> :
                <>
                    <div className="pb-6 pt-2">
                        {loggedIn && !profile?.firstName &&
                            <div className="border-4 border-dashed border-graydark rounded-md p-4 mb-12 flex flex-col items-center">
                                <div className="text-lg font-bold">
                                    <NotificationIcon />Don't forget to fill out your Profile!
                                </div>
                                <div>
                                    Enter your name and address to get credit on the Leaderboards.
                                </div>
                                <div className="mt-3">
                                    <Link to="/profile">
                                        <Button variant="contained" color="primary">
                                            Go to Profile
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        }
                        {currentRegisteredEvents?.length > 0 &&
                            <>
                                <div className="flex items-end">
                                    <Label className="text-red">
                                        My Events
                                    </Label>
                                    {currentRegisteredEvents?.length > 0 &&
                                        <div className="flex-1 flex justify-end mb-2">
                                            {loggingActivity ?
                                                <div className="mr-4">
                                                    <CircularProgress size={30}/>
                                                </div> :
                                                <div>
                                                    <TrackableButton eventName="dashboard_log_activity_click"
                                                                     variant="contained"
                                                                     onClick={() => setShowLogActivity(true)}>
                                                        <ActivityIcon className="mr-1"/>Log Activity
                                                    </TrackableButton>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                {currentRegisteredEvents?.length > 0 ?
                                    <div>
                                        {currentRegisteredEvents?.map((event: any, index: number) => (
                                            <EventItem key={event.id} event={event}
                                                       className={cx({"mb-6": index < currentRegisteredEvents.length - 1})}
                                                       isActive/>
                                        ))}
                                    </div> :
                                    // This should no longer be shown, keeping it in case we want something back
                                    <div className="text-center mt-6">
                                        <div className="text-2xl font-bold mb-4">
                                            No active registrations.
                                        </div>
                                        <div>
                                            Register for a challenge below to see how you stack up against the
                                            competition!
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    {currentUnregisteredEvents?.length > 0 &&
                        <div className="mt-6">
                            <Label className="text-blue">
                                Current Events
                            </Label>
                            {currentUnregisteredEvents?.map((event: any, index: number) => (
                                <EventItem key={event.id} event={event} onRegisterClick={eventId => onRegisterClick(eventId)} className={cx({ "mb-6": index < currentUnregisteredEvents.length - 1})} />
                            ))}
                        </div>
                    }
                    <div className="mt-12">
                        <Label className="text-green">
                            Upcoming Events
                        </Label>
                        {upcomingEvents?.map((event: any, index: number) => (
                            <EventItem key={event.id} event={event} onRegisterClick={eventId => onRegisterClick(eventId)} className={cx({ "mb-6": index < upcomingEvents.length - 1})} isActive={false} />
                        ))}
                    </div>
                </>
            }
            <HCDialog
                open={Boolean(eventId)}
                title={`Event Registration`}
                actions={[]}
                onClose={onDismiss}
                fullScreen>
                <EventRegistration eventId={eventId || ""} onDismiss={onDismissEventRegistration} />
            </HCDialog>
            <HCDialog
                open={Boolean(leaderboardId)}
                title={`Leaderboard`}
                actions={[]}
                onClose={onDismiss}
                fullScreen>
                <Leaderboard eventId={parseInt(leaderboardId || "-1", 10)} />
            </HCDialog>
            <LogActivityDialog open={showLogActivity} onClose={() => setShowLogActivity(false)} />
        </div>
    )
};

export default Dashboard;
