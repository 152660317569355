/*global google*/
/*eslint no-undef: "error"*/

import React, { useState, useRef, useEffect, useCallback, forwardRef } from 'react';
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import {Search} from "@mui/icons-material";

import './index.css';

interface Props {
    onPlaceSelected: (place: any) => void;
    error?: any;
}

const PlacesSearch: React.FC<Props> = forwardRef(({ onPlaceSelected, error }, ref) => {
    const inputRef = useRef(null);
    const [value, setValue] = useState('');

    const initializePlaces = useCallback(() => {
        // @ts-ignore
        if (typeof google !== "undefined") {
            const options = {
                // bounds: defaultBounds,
                // KBM - For some reason, "as" does not seem to work, and removing this is the only way to get American Samoa addresses to show /shrug
                // componentRestrictions: { country: ["us", "pr", "vi", "gu", "np", "ws"] },
                fields: ["address_component"],
                strictBounds: false,
                types: ["establishment", "geocode"],
            };

            // @ts-ignore
            const autocomplete = new google.maps.places.Autocomplete(inputRef.current, options);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                onPlaceSelected(place);
                setValue('');
            });
        }
    }, [onPlaceSelected]); //

    useEffect(() => {
        if (inputRef.current) {
            let script = document.getElementById("places");

            if (!script) {
                script = document.createElement("script");
                script.setAttribute("src", `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_FIREBASE_API_KEY}&libraries=places&callback=initializePlaces`);
                script.setAttribute("id", "places");
                // @ts-ignore
                window.initializePlaces = initializePlaces;
                document.head.appendChild(script);
            } else {
                initializePlaces();
            }
        }
    }, [inputRef, initializePlaces]);

    return (
        <TextField
            placeholder="1234 Place Dr, Austin, TX 78704"
            label="Address Search"
            inputRef={inputRef}
            value={value}
            onChange={e => setValue(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end"><Search /></InputAdornment>
            }}
            error={error}
            fullWidth />
    )
});

export default PlacesSearch;
