import React, { MouseEventHandler } from 'react';
import { IconButton, ButtonProps } from "@mui/material";

import api from "../../../../../api";

export interface Props extends ButtonProps {
    eventName?: string;
}

const TrackableIconButton: React.FC<Props> = ({ onClick, eventName, ...otherProps }) => {
    const internalOnClick: MouseEventHandler<HTMLButtonElement> = e => {
        if (eventName) {
            api.logEvent(eventName);
        }
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <IconButton onClick={internalOnClick} {...otherProps} />
    )
}

export default TrackableIconButton;