import React from 'react';
import { Navigate, useParams } from "react-router-dom";

const LegacyLeaderboardRedirect: React.FC = () => {
    const { eventId } = useParams();

    return (
        <Navigate to={`/dashboard/leaderboard/${eventId}`} />
    )
};

export default LegacyLeaderboardRedirect;