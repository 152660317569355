import React, { useEffect, useRef } from 'react';

// @ts-ignore
import lottie from 'lottie-web';

interface Props extends React.HTMLProps<HTMLDivElement> {
    animation: "burn" | "road" | "clock" | "loading" | "coming-soon" | "celebrate" | "congrats" | "celebrate-jump" | "error" | "welcome"
}

const LottieAnimation: React.FC<Props> = ({ animation, ...otherProps }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // @ts-ignore
        if (containerRef.current && !containerRef.current.attributes[`${animation}-loading`]) {
            // @ts-ignore
            containerRef.current.attributes[`${animation}-loading`] = true;
            lottie.loadAnimation({
                container: containerRef.current, // Required
                path: `/lottie/${animation}.json`, // Required
                renderer: 'svg', // Required
                loop: true, // Optional
                autoplay: true, // Optional
                name: "Hello World", // Name for future reference. Optional.
            });
        }
    }, [containerRef, animation]);

    return (
        <div {...otherProps} ref={containerRef} />
    )
};

export default LottieAnimation;