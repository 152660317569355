export function saveLocal(key: string, value: any, asJson: boolean) {
    // console.log("saving", key, value, asJson);
    if (value && asJson) {
        // console.log("saving", key);
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        localStorage.setItem(key, value);
    }
}

export function getLocal(key: string, asJson: boolean) {
    var value = localStorage.getItem(key);

    if (value && value !== "undefined" && asJson) {
        return JSON.parse(value);
    }

    return value;
}

// const SHIRT_SIZES = [
//     ['S', 'Small'],
//     ['M', 'Medium'],
//     ['L', 'Large'],
//     ['XL', 'X-Large'],
//     ['XX', 'XX-Large'],
//     ['DN', 'I prefer to donate the cost of my shirt'],
// ];

export const ACTIVITY_TYPES: {[id: number]: string} = {
    5: "Running",
    6: "Walking",
    7: "Biking/Cycling",
    8: "Swimming",
    9: "Skating/Skateboarding",
    10: "Other (Zumba, Group Class, Paddleboard, etc.)",
    11: "Weightlifting/Crossfit/Resistance",
    12: "Yoga/CubeFit",
    13: "Farming/Gardening",
}