import React, {useEffect, useState, useCallback, useRef} from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
    Card,
    CardContent,
    CircularProgress,
    TextField,
} from "@mui/material";
import {Edit, Save, WrongLocation} from "@mui/icons-material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Form, Formik } from "formik";
import { object, string, date } from "yup";
import { format, parse } from "date-fns";
import { useForm, UseFormSetValue, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import LoadingView from "../_common/loading";
import CustomFormikField from "../_common/formik/customField";
import PlacesSearch from "../_common/placesSearch";
import HCDialog from "../_common/dialog";
import {
    authInitializedSelector,
    profileLoadingSelector,
    profileSavingSelector,
    profileSelector, updatingEmailSelector, userIdSelector
} from "../../redux/login/selectors";
import { fetchUserProfile, updateUserProfile, updateEmail } from "../../redux/login";
import GenderSelect from "../_common/select/gender";
import TrackableButton from "../_common/buttons/trackable";
import TrackableIconButton from "../_common/buttons/trackable/iconButton";
import api from "../../api";

const IMAGES = [
    { name: "Blue Flag", image: "flag_blue.png" },
    { name: "Pink Flag", image: "flag_pink.png" },
    { name: "Green Leaf", image: "leafgreen_M3.png" },
    { name: "Pink Leaf", image: "leafpink_M3.png" },
    { name: "Blue Trophy", image: "manwithtrophy_blue.png" },
    { name: "Orange Trophy", image: "manwithtrophy_orange.png" },
    { name: "Pink Trophy", image: "manwithtrophy_pink.png" },
    { name: "Blue #1", image: "number_one_finger_blueM3.png" },
    { name: "Orange #1", image: "number_one_finger_orangeM3.png" },
    { name: "Pink #1", image: "number_one_finger_pinkM3.png" },
    { name: "Podium", image: "podium.png" },
    { name: "Blue Thumbs Up", image: "thumbsup_blue.png" },
    { name: "Green Thumbs Up", image: "thumbsup_green.png" },
    { name: "Trophy", image: "trophy.png" }
];

interface Props {
    onSave?: () => void;
    condensed?: boolean;
}

const Profile: React.FC<Props> = ({ onSave, condensed = false }) => {
    const [changeEmailOpen, setChangeEmailOpen] = useState(false);
    const loading = useSelector(profileLoadingSelector);
    const saving = useSelector(profileSavingSelector);
    const profile = useSelector(profileSelector);
    const authInitialized = useSelector(authInitializedSelector);
    const updatingEmail = useSelector(updatingEmailSelector);
    const userId = useSelector(userIdSelector);
    const submitRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!profile && authInitialized && userId) {
            // @ts-ignore
            dispatch(fetchUserProfile(userId, enqueueSnackbar));
        }
    }, [dispatch, profile, authInitialized, userId, enqueueSnackbar]);

    const onPlaceSelected = useCallback((place: any, setFieldValue: any) => {
        // Log this
        api.logEvent("onPlaceSelected", {...place, email: profile.email});
        const streetNumber = _getComponent(place, "street_number");
        const streetName = _getComponent(place, "route");
        const premise = _getComponent(place, "premise");
        const subpremise = _getComponent(place, "subpremise");
        const establishment = _getComponent(place, "establishment");
        const neighborhood = _getComponent(place, "neighborhood");
        const locality = _getComponent(place, "locality");
        const sublocality = _getComponent(place, "sublocality");
        const adminArea1 = _getComponent(place, "administrative_area_level_1");
        const city = locality || sublocality || neighborhood;
        const state = adminArea1 || locality;
        const zip = _getComponent(place, "postal_code");
        const country = _getComponent(place, "country");
        if ((streetName || premise || subpremise || establishment) && city && state && zip) {
            setFieldValue('address.line1', `${streetNumber?.short_name || ""} ${streetName?.short_name || premise?.short_name || subpremise?.short_name || establishment?.short_name}`.trimStart(), {shouldValidate: false});
            setFieldValue('address.city', city?.short_name, {shouldValidate: false});
            setFieldValue('address.stateCode', state?.short_name?.length === 2 ? state?.short_name : country.short_name, {shouldValidate: false});
            setFieldValue('address.countryCode', country.short_name, {shouldValidate: false});
            setFieldValue('address.zip', zip?.short_name, {shouldValidate: false});
        } else {
            enqueueSnackbar("Please choose an Address with a Street Number", {variant: "error"});
        }
    }, [enqueueSnackbar, profile?.email]);

    const updateProfile = (values: any) => {
        // @ts-ignore
        dispatch(updateUserProfile(values, enqueueSnackbar));
    }

    const onSubmit = (data: any) => {
        updateProfile({
            ...data,
            dob: format(data.dob, "yyyy-MM-dd")
        });
        if (onSave) {
            onSave();
        }
    }

    return (
        <div className="mx-2 md:mx-8">
            {loading ?
                <LoadingView title="Loading Profile..."/> :
                <EditProfileForm
                    saving={saving}
                    updatingEmail={updatingEmail}
                    onSubmit={onSubmit}
                    onPlaceSelected={onPlaceSelected}
                    setChangeEmailOpen={setChangeEmailOpen}
                    condensed={condensed} />
            }
            <HCDialog
                open={changeEmailOpen}
                title="Update Email"
                actions={[
                    { label: "Cancel", eventName: "profile_update_email_dialog_cancel_click", onClick: () => setChangeEmailOpen(false) },
                    { label: "Confirm", eventName: "profile_update_email_dialog_confirm_click", onClick: () => submitRef.current?.click() }
                ]}
                onClose={() => setChangeEmailOpen(false)}>
                <div>
                    <Formik
                        initialValues={{
                            newEmail: ""
                        }}
                        validationSchema={object().shape({
                            newEmail: string().email("Please enter a valid email.").required("Please enter a valid email.")
                        })}
                        onSubmit={values => {
                            // @ts-ignore
                            dispatch(updateEmail(values.newEmail, () => {}, enqueueSnackbar));
                            setChangeEmailOpen(false);
                        }}
                    >
                        <Form>
                            <div className="w-[270px]">
                                <input type="submit" ref={submitRef} className="hidden" />
                                {/* @ts-ignore */}
                                <CustomFormikField
                                    CustomComponent={TextField}
                                    name="newEmail"
                                    label="New Email"
                                    placeholder="your.email@gmail.com"
                                    variant="standard"
                                    fullWidth />
                            </div>
                        </Form>
                    </Formik>
                </div>
            </HCDialog>
        </div>
    )
};

interface EditProfileFormProps {
    saving: boolean;
    updatingEmail: boolean;
    onSubmit: (data: any) => void;
    onPlaceSelected: (place: any, setValue: UseFormSetValue<any>) => void;
    setChangeEmailOpen: (value: boolean) => void;
    condensed?: boolean;
}

const EditProfileForm: React.FC<EditProfileFormProps> = ({ saving, updatingEmail, onSubmit, onPlaceSelected, setChangeEmailOpen, condensed = false }) => {
    const [showImgDialog, setShowImgDialog] = useState(false);
    const profile = useSelector(profileSelector);
    const { register, handleSubmit, setValue, watch, control, formState: { errors } } = useForm({
        defaultValues: {
            gender: "",
            firstName: "",
            lastName: "",
            address: {
                line1: "",
                line2: "",
                city: "",
                stateCode: "",
                zip: "",
                countryCode: "",
            },
            ...profile,
            dob: profile?.dob ? parse(profile.dob, "yyyy-MM-dd", new Date()) : new Date(),
        },
        resolver: yupResolver(object().shape({
            image: string().required(),
            dob: date().required(),
            gender: string().required(),
            firstName: string().required(),
            lastName: string().required(),
            address: object().shape({
                line1: string().required("Search for an address."),
                line2: string(),
                city: string().required(),
                stateCode: string().required(),
                zip: string().required(),
                countryCode: string().required()
            })
        }))
    });

    const address = watch("address");

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex md:hidden justify-end mt-2 mb-4">
                    {saving ?
                        <CircularProgress size={30} /> :
                        // @ts-ignore
                        <TrackableButton eventName="profile_save_click" variant="contained" color="primary" type="submit">
                            <Save className="mr-1" />Save
                        </TrackableButton>
                    }
                </div>
                {!condensed &&
                    <div className="flex">
                        <div className="flex flex-col md:flex-row items-center justify-center flex-1">
                            <div>
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <img src={`${process.env.REACT_APP_ADMIN_URL}/static/images/user-profile/${field.value}`} alt="Profile" className="w-24" />
                                    )} />
                            </div>
                            <div className="flex items-center md:pl-4 flex-1">
                                <TrackableButton eventName="profile_choose_image_click" onClick={() => setShowImgDialog(true)}>
                                    <Edit className="mr-1" />Choose Profile Image
                                </TrackableButton>
                            </div>
                        </div>
                        <div className="hidden md:flex mt-2">
                            <div>
                                {saving ?
                                    <CircularProgress size={30} /> :
                                    // @ts-ignore
                                    <TrackableButton eventName="profile_save_click" variant="contained" color="primary" type="Submit">
                                        <Save className="mr-1" />Save
                                    </TrackableButton>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="mt-8 uppercase font-bold text-sm mb-2">
                    Contact Info
                </div>
                <div>
                    <Card>
                        <CardContent>
                            <div className="flex">
                                <div className="hidden md:block flex-1 mr-2">
                                    <Controller
                                        name="dob"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DesktopDatePicker
                                                label="Birthdate"
                                                inputFormat="MM/dd/yyyy"
                                                {...field}
                                                renderInput={(params) => <TextField {...params} variant="standard" fullWidth />} />
                                        )}/>
                                </div>
                                <div className="md:hidden flex-1 mr-2">
                                    <Controller
                                        name="dob"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                        <MobileDatePicker
                                            label="Birthdate"
                                            inputFormat="MM/dd/yyyy"
                                            {...field}
                                            renderInput={(params) => <TextField {...params}  variant="standard" fullWidth />}
                                        />
                                    )}/>
                                </div>
                                <div className="flex-1 ml-2">
                                    <Controller
                                        name="gender"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <GenderSelect {...field} error={Boolean(errors.gender?.type)} />}
                                    />
                                </div>
                            </div>
                            <div className="flex mt-6">
                                <div className="flex-1 mr-2">
                                    <TextField
                                        {...register("firstName")}
                                        label="First Name"
                                        placeholder="John"
                                        variant="standard"
                                        error={Boolean(errors.firstName?.type)}
                                        helperText={errors.firstName?.message?.toString() || ""}
                                        fullWidth />
                                </div>
                                <div className="flex-1 ml-2">
                                    <TextField
                                        {...register("lastName")}
                                        label="Last Name"
                                        placeholder="Smith"
                                        variant="standard"
                                        error={Boolean(errors.lastName?.type)}
                                        helperText={errors.lastName?.message?.toString() || ""}
                                        fullWidth />
                                </div>
                            </div>
                            {!condensed &&
                                <div className="mt-6">
                                    <div className="mb-1 font-roboto text-[rgba(0,0,0,0.6)] text-xs">
                                        Email
                                    </div>
                                    <div className="flex items-center">
                                        <div>
                                            {profile.email}
                                        </div>
                                        <div className="ml-4">
                                            {updatingEmail ?
                                                <CircularProgress size={30} /> :
                                                <TrackableIconButton eventName="profile_update_email_click" onClick={() => setChangeEmailOpen(true)}>
                                                    <Edit />
                                                </TrackableIconButton>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </div>
                <div className="mt-16 uppercase font-bold text-sm mt-8 mb-2">
                    Address
                </div>
                <Card>
                    <CardContent>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex-1 pt-2">
                                <PlacesSearch
                                    onPlaceSelected={(place: any) => onPlaceSelected(place, setValue)} />
                                {/*<PlacesSearch onPlaceSelected={place => onPlaceSelected(place, setFieldValue)} />*/}
                            </div>
                            <div className="flex-1 mt-4 md:mt-0 md:ml-8">
                                {address.line1 ?
                                    <div>
                                        <div>
                                            <div className="mb-1 font-bold text-lg">
                                                {address.line1}
                                            </div>
                                            <TextField
                                                {...register("address.line2")}
                                                label="Address Line 2"
                                                placeholder="Unit #4b"
                                                variant="standard"
                                                fullWidth />
                                            <div className="mt-4">
                                                {address.city}, {address.stateCode} {address.zip}
                                            </div>
                                        </div>
                                    </div> :
                                    <div>
                                        <div className="flex justify-center mb-1">
                                            <WrongLocation fontSize="large" />
                                        </div>
                                        <div className={cx("text-center font-bold", {
                                            // @ts-ignore
                                            "text-red": errors.address?.line1?.type
                                        })}>
                                            Search for an Address and select one to set your Address.
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </CardContent>
                </Card>
                {condensed &&
                    <div className="mt-6 flex justify-center">
                        <TrackableButton eventName="registration-profile-save-click" variant="contained" color="primary" type="submit">
                            Save Leaderboard Info
                        </TrackableButton>
                    </div>
                }
                <HCDialog
                    open={showImgDialog}
                    title="Choose Profile Image"
                    actions={[
                        { label: "Cancel", eventName: "profile_choose_image_dialog_cancel_click", onClick: () => setShowImgDialog(false) },
                    ]}
                    onClose={() => setShowImgDialog(false)}>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-6">
                        {IMAGES.map((img, index) => (
                            <div key={`profile-image-${index}`} className="cursor-pointer" onClick={() => {
                                setValue("image", img.image);
                                setShowImgDialog(false);
                            }}>
                                <div className="flex justify-center">
                                    <img src={`${process.env.REACT_APP_ADMIN_URL}/static/images/user-profile/${img.image}`} alt={img.name} className="w-20" />
                                </div>
                                <div className="text-center">
                                    {img.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </HCDialog>
            </form>
        </div>
    );
}

function _getComponent(place: any, componentName: string) {
    return place.address_components?.find((component: any) => component.types.includes(componentName)) || "";
}

export default Profile;
