import React, {useEffect, useState} from 'react';
import {Button, SwipeableDrawer, AppBar, Toolbar} from "@mui/material";
import {Menu} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {Route, Routes, useSearchParams, useLocation} from "react-router-dom";
import {Navigate, useNavigate} from "react-router-dom";

import Dashboard from "../dashboard";
import Nav from "../nav";
import NavSponsors from "../nav/sponsors";
import Leaderboards from "../leaderboards";
import MyActivity from "../activity";
import Profile from "../profile";
import AuthListener from "../auth";
import LoginDialog from "../login/dialog";
import ResetPasswordDialog from "../login/dialog/resetPassword";
import Feedback from "../feedback";
import TrackableButton from "../_common/buttons/trackable";
import usePageViewTracker from "../../hooks/usePageViewTracker";
import {fetchActiveRegistrations, loading, fetchEvents, fetchOrganizations} from "../../redux/events";
import {fetchUserProfile, signInWithToken} from "../../redux/login";
import {
    authInitializedSelector,
    claimsSelector, impersonatingSelector,
    loggedInSelector, userIdSelector,
} from "../../redux/login/selectors";
import { signOut } from "../../redux/login";

import logo from "../../img/HealthCode-logo-reg-mark.jpeg";
import TrackableIconButton from "../_common/buttons/trackable/iconButton";
import WelcomeDialog from "../welcome";
import LegacyLeaderboardRedirect from "../leaderboards/legacyRedirect";
import ImpersonateUserDialog from "../impersonate";
import {Helmet} from "react-helmet-async";

const Home: React.FC = () => {
    const [showLogin, setShowLogin] = useState(Boolean(document.location.pathname.match(/login/i)));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showWelcome, setShowWelcome] = useState(false);
    const [showImpersonateUser, setShowImpersonateUser] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const loggedIn = useSelector(loggedInSelector);
    const authInitialized = useSelector(authInitializedSelector);
    const userClaims = useSelector(claimsSelector);
    const userId = useSelector(userIdSelector);
    const impersonating = useSelector(impersonatingSelector);
    const navigate = useNavigate();
    const location = useLocation();
    usePageViewTracker();

    const shouldFetch = (!loggedIn || (loggedIn && authInitialized));
    const showResetPassword = searchParams.get("mode") === "resetPassword";

    useEffect(() => {
        if (loggedIn && userId && (showLogin || showResetPassword)) {
            setShowLogin(false);
            // @ts-ignore
            dispatch(loading(true));

            // @ts-ignore
            dispatch(fetchUserProfile(userId, enqueueSnackbar));
            setSearchParams("");
        }
    }, [loggedIn, showLogin, showResetPassword, userId, setSearchParams, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (loggedIn && authInitialized && userId) {
            // @ts-ignore
            dispatch(fetchActiveRegistrations(userId, enqueueSnackbar));
            // @ts-ignore
            dispatch(fetchUserProfile(userId, enqueueSnackbar));
        }
    }, [loggedIn, authInitialized, userId, dispatch, enqueueSnackbar]);

    useEffect(() => {
        // If not logged in, fetch events immediately, else wait for Auth to initialize so auth info is sent with callable
        if (shouldFetch) {
            // @ts-ignore
            dispatch(fetchEvents(enqueueSnackbar));
        }
    }, [shouldFetch, loggedIn, dispatch, enqueueSnackbar]);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchOrganizations(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (searchParams.get("token")) {
            // @ts-ignore
            dispatch(signInWithToken(searchParams.get("token"), enqueueSnackbar));
        }
    }, [searchParams, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (location.pathname.match(/dashboard\/intro/i)) {
            setShowWelcome(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (impersonating) {
            enqueueSnackbar("WARNING: You are impersonating a user", { variant: "warning", persist: true });
        }
    }, [impersonating, enqueueSnackbar]);

    const logout = () => {
        // @ts-ignore
        dispatch(signOut(enqueueSnackbar));
    }

    const onLogoutClick = () => {
        if (loggedIn) {
            logout();
            navigate("/");
        } else {
            setShowLogin(true);
        }
    };

    return (
        <div className="h-full bg-red-500 flex">
            <Helmet>
                <title>HealthCode</title>
            </Helmet>
            <div className="bg-white shadow-lg hidden md:flex flex-col w-[300px]">
                <div className="py-6 px-4 border-0 border-b border-solid border-graylight">
                    <a href="https://healthcode.org" target="_self" rel="noopener noreferrer" className="md:hidden" >
                        <img src={logo} className="h-12" alt="HealthCode"/>
                    </a>
                    <a href="https://healthcode.org" target="_blank" rel="noopener noreferrer" className="hidden md:block" >
                        <img src={logo} className="h-12" alt="HealthCode"/>
                    </a>
                </div>
                <div className="pt-2 flex">
                    <Nav setShowWelcome={setShowWelcome} />
                </div>
                <div className="flex-1 flex items-end pb-4">
                    <div className="top-divider">
                        <NavSponsors />
                    </div>
                </div>
            </div>
            <div className="md:hidden">
                <SwipeableDrawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    onOpen={() => setDrawerOpen(true)}
                >
                    <div className="w-[300px] flex flex-col h-full">
                        <div className="py-6 px-4 border-0 border-b border-solid border-graylight">
                            <a href="https://healthcode.org" target="_blank" rel="noopener noreferrer" >
                                <img src={logo} className="h-12" alt="HealthCode"/>
                            </a>
                        </div>
                        <Nav onItemClick={() => setDrawerOpen(false)} setShowWelcome={setShowWelcome} />
                        <div className="flex-1 flex items-end pb-4">
                            <div className="top-divider">
                                <NavSponsors />
                            </div>
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
            <div className="flex flex-1 h-full flex-col overflow-x-hidden">
                <AppBar position="sticky">
                    <Toolbar>
                        <div className="md:hidden mr-4 -mt-[3px]">
                            <TrackableIconButton eventName="home_menu_click" onClick={() => setDrawerOpen(true)}>
                                <Menu />
                            </TrackableIconButton>
                        </div>
                        <div className="flex-1 font-extrabold text-xl font-os text-graydark flex items-center">
                            <div>
                                <Routes>
                                    <Route path="/events" element={ "Dashboard" }>
                                        <Route path=":eventId" element={ "Dashboard" } />
                                    </Route>
                                    <Route path="/register" element={ "Dashboard" }>
                                        <Route path=":eventId" element={ "Dashboard" } />
                                    </Route>
                                    <Route path="/dashboard" element={ "Dashboard" }>
                                        <Route path="leaderboard/:eventId" element={ "Dashboard" } />
                                        <Route path="intro" element={ "Dashboard" } />
                                        <Route path=":eventId" element={ "Dashboard" } />
                                    </Route>
                                    <Route path="/leaderboards" element={ "Leaderboards" }>
                                        <Route path=":eventId" element={ "Leaderboards" } />
                                    </Route>
                                    <Route path="/activity" element={ "My Activity" }>
                                        <Route path="leaderboards/:eventId" element={ "My Activity" } />
                                    </Route>
                                    <Route path="/profile" element={ "Profile" } />
                                    <Route path="/feedback" element={ "Feedback" } />
                                </Routes>
                            </div>
                        </div>
                        {userClaims?.admin &&
                            <div className="mr-3 hidden sm:block">
                                <Button variant="contained" onClick={() => setShowImpersonateUser(true)}>
                                    Impersonate User
                                </Button>
                            </div>
                        }
                        <div className="text-graydark">
                            <TrackableButton variant="contained" onClick={onLogoutClick} color="inherit" eventName={loggedIn ? "sign_out_click" : "log_in_click"}>
                                {loggedIn ? "Sign out" : "Login"}
                            </TrackableButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="flex-1 bg-graylightest p-4 md:p-6 overflow-y-scroll">
                    <Routes>
                        <Route path="/events" element={ <Dashboard /> }>
                            <Route path=":eventId" element={ <Dashboard /> } />
                        </Route>
                        <Route path="/register" element={ <Dashboard /> }>
                            <Route path=":eventId" element={ <Dashboard /> } />
                        </Route>
                        <Route path="/dashboard" element={ <Dashboard /> }>
                            <Route path="login" element={ <Dashboard /> } />
                            <Route path="leaderboard/:leaderboardId" element={ <Dashboard /> } />
                            <Route path="intro" element={ <Dashboard /> } />
                            <Route path=":eventId" element={ <Dashboard /> } />
                        </Route>
                        <Route path="/leaderboards/:eventId" element={ <LegacyLeaderboardRedirect /> } />
                        <Route path="/leaderboards" element={ <Leaderboards /> } />
                        <Route path="/activity" element={ <MyActivity /> }>
                            <Route path="leaderboards/:eventId" element={ <MyActivity /> } />
                        </Route>
                        <Route path="/profile" element={ <Profile /> } />
                        <Route path="/feedback" element={ <Feedback /> } />
                        <Route path="/login" element={ <Navigate to="/dashboard/login" /> } />
                        <Route path="*" element={ <Navigate to="/dashboard" /> } />
                    </Routes>
                </div>
            </div>
            <LoginDialog open={showLogin} onClose={() => setShowLogin(false)} />
            <AuthListener />
            <ResetPasswordDialog open={showResetPassword} onClose={() => navigate("/")} />
            <WelcomeDialog open={showWelcome} title={<></>} actions={[]} onClose={() => setShowWelcome(false)} />
            {userClaims?.admin &&
                <ImpersonateUserDialog open={showImpersonateUser} onClose={() => setShowImpersonateUser(false)} />
            }
        </div>
    );
}

export default Home;
