import React, { useEffect } from 'react';
import { getApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";

import { userAuthed, userUnauthed } from "../../redux/login";

const AuthListener: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        onAuthStateChanged(getAuth(getApp()), async (user) => {
            if (user) {
                const token = await user.getIdTokenResult(true);

                Sentry.setUser({email: user.email || undefined});

                dispatch(userAuthed({claims: token.claims }));
            } else {
                Sentry.setUser(null);
                dispatch(userUnauthed({}));
            }
        });
    }, [dispatch]);

    return (
        <></>
    );
}

export default AuthListener;
