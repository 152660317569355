import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import api from "../api";

export default function usePageViewTracker() {
    const location = useLocation();

    useEffect(() => {
        api.logEvent("navigate", {location: location.pathname});
    }, [location]);
};
