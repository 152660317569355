import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

import { ACTIVITY_TYPES } from "../../../../utils";

const ActivityTypeSelect: React.FC<SelectProps> = ({ ...otherProps }) => {
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="organization-group-label" variant="standard">Activity Type</InputLabel>
                <Select
                    labelId="organization-group-label"
                    id="organization-group-select"
                    label="Group / Team (Optional)"
                    variant="standard"
                    {...otherProps}
                >
                    <MenuItem value="">Choose Activity Type</MenuItem>
                    {Object.entries(ACTIVITY_TYPES).map(([key, value]) => (
                        <MenuItem key={`activity-type-${key}`} value={key}>{value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

export default ActivityTypeSelect;