import React, { MouseEventHandler } from 'react';
import { Button, ButtonProps } from "@mui/material";

import api from "../../../../api";

export interface Props extends ButtonProps {
    eventName?: string;
}

const TrackableButton: React.FC<Props> = ({ onClick, eventName, ...otherProps }) => {
    const internalOnClick: MouseEventHandler<HTMLButtonElement> = e => {
        if (eventName) {
            api.logEvent(eventName);
        }
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <Button onClick={internalOnClick} {...otherProps} />
    )
}

export default TrackableButton;