import React, { useState } from 'react';
import {ButtonProps, CircularProgress} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import { useSnackbar } from "notistack";

import { stravaOauth, disconnectStrava } from "../../../../redux/login";
import {profileSelector} from "../../../../redux/login/selectors";

import stravaLogo from "../../../../img/trackers/Strava_Logo_white.svg";
import stravaLogoDark from "../../../../img/trackers/Strava_Logo_logo_dark.svg";
import HCDialog from "../../dialog";
import TrackableButton from "../trackable";

// TODO: Select authToken from profile to display "linked" state
const StravaAuthButton: React.FC<ButtonProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const profile = useSelector(profileSelector);

    const onAuthClick = () => {
        setLoading(true);
        // @ts-ignore
        dispatch(stravaOauth(setLoading, enqueueSnackbar));
    }

    const onDisconnect = () => {
        // @ts-ignore
        dispatch(disconnectStrava(enqueueSnackbar));
        setOpen(false);
    }

    const connected = profile?.strava.accessToken;

    return (
        <div className="h-12 flex items-center justify-center">
            {loading ?
                <CircularProgress size={30} className="a" /> :
                <TrackableButton eventName={connected ? "strava_disconnect_click" : "strava_connect_click"} variant="contained" {...props} onClick={connected ? () => setOpen(true) : onAuthClick} color={connected ? "inherit" : "primary"}>
                    {connected ? "Disconnect" : "Connect"} <img src={connected ? stravaLogoDark : stravaLogo} alt="Strava" className="h-3 ml-2" />
                </TrackableButton>
            }
            <HCDialog
                open={open}
                title="Disconnect Strava"
                actions={[
                    { label: 'Cancel', eventName: "strava_disconnect_dialog_cancel_click", onClick: () => setOpen(false) },
                    { label: 'Disconnect', eventName: "strava_disconnect_dialog_disconnect_click", onClick: onDisconnect },
                ]}
                onClose={() => setOpen(false)}>
                Are you sure you want to disconnect Strava?
            </HCDialog>
        </div>
    );
}

export default StravaAuthButton;