import React, { useState } from 'react';
import {ButtonProps, CircularProgress} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import { useSnackbar } from "notistack";

import { garminOauth, disconnectGarmin } from "../../../../redux/login";
import {profileSelector} from "../../../../redux/login/selectors";

import garminLogo from "../../../../img/trackers/garmin_logo_white.svg";
import garminLogoDark from "../../../../img/trackers/garmin_logo_dark.svg";
import HCDialog from "../../dialog";
import TrackableButton from "../trackable";

// TODO: Select authToken from profile to display "linked" state
const GarminAuthButton: React.FC<ButtonProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const profile = useSelector(profileSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const onAuthClick = () => {
        setLoading(true);
        // @ts-ignore
        dispatch(garminOauth(setLoading, enqueueSnackbar));
    }

    const onDisconnect = () => {
        // @ts-ignore
        dispatch(disconnectGarmin(enqueueSnackbar));
        setOpen(false);
    }

    const connected = profile?.garmin.accessToken

    return (
        <div className="h-12 flex items-center justify-center">
            {loading ?
                <CircularProgress size={30} className="a" /> :
                <TrackableButton eventName={connected ? "garmin_disconnect_click" : "garmin_connect_click"} variant="contained" {...props} onClick={connected ? () => setOpen(true) : onAuthClick} color={connected ? "inherit" : "primary"}>
                    {connected ? "Disconnect" : "Connect"} <img src={connected ? garminLogoDark : garminLogo} alt="Garmin" className="h-6 ml-1" />
                </TrackableButton>
            }
            <HCDialog
                open={open}
                title="Disconnect Garmin"
                actions={[
                    { label: 'Cancel', eventName: "garmin_disconnect_dialog_cancel_click", onClick: () => setOpen(false) },
                    { label: 'Disconnect', eventName: "garmin_disconnect_dialog_disconnect_click", onClick: onDisconnect },
                ]}
                onClose={() => setOpen(false)}>
                Are you sure you want to disconnect Garmin?
            </HCDialog>
        </div>
    );
}

export default GarminAuthButton;