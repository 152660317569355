import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import HCDialog from "../../_common/dialog";
import Login from "../index";
import RegisterForm from "../registerForm";

interface Props {
    open: boolean;
    onClose: () => void;
    registerDefault?: boolean;
}

const LoginDialog: React.FC<Props> = ({ open, onClose, registerDefault = false, }) => {
    const [tab, setTab] = useState(!registerDefault ? 0 : 1);

    return (
        <HCDialog
            open={open}
            title={(
                <div className="border-0 border-solid border-b border-graylight">
                    <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} aria-label="basic tabs example" variant="fullWidth">
                        <Tab label="Sign In" />
                        <Tab label="Register" />
                    </Tabs>
                </div>
            )}
            actions={[
                { label: 'cancel', eventName: "login_dialog_cancel_click", onClick: onClose }
            ]}
            onClose={onClose}>
            <div className="w-[275px] md:w-[500px]">
                {tab === 0 ?
                    <Login /> :
                    <div>
                        <RegisterForm />
                    </div>
                }
            </div>

        </HCDialog>
    )
};

export default LoginDialog;
