import React, {useState} from 'react';
import { useSelector } from "react-redux";
import {
    MilitaryTech,
    Groups,
    Leaderboard
} from "@mui/icons-material";

import {
    eventSelector,
    eventStatsSelector,
} from "../../../../redux/events/selectors";
import {Tooltip} from "@mui/material";
import HCDialog from "../../../_common/dialog";
import OrganizationLeaderboard from "../../../leaderboards/organization";
import TrackableButton from "../../../_common/buttons/trackable";

interface Props {
    eventId: string | number;
    orgName?: string;
    orgId?: string | number;
    groupId?: string | number;
    groupName?: string;
}

const EventStandings: React.FC<Props> = ({ eventId, orgName, orgId, groupId, groupName, }) => {
    const [showOrgLeaderboard, setShowOrgLeaderboard] = useState<string|number|undefined|null>(null);
    const eventStats = useSelector(eventStatsSelector(eventId));
    const event = useSelector(eventSelector(eventId));

    const orgOverall = eventStats?.orgs?.rank;
    const groupOverall = eventStats?.groups?.rank;

    return (
        <div>
            <div className="grid grid-cols-2">
                <div className="col-span-full md:col-span-1 border-0 border-solid border-graylight md:border-r md:pr-6 flex items-center">
                    <div>
                        <MilitaryTech fontSize="large" className="mr-2" />
                    </div>
                    <div className="flex-1">
                        Overall
                    </div>
                    {event?.registration?.totals?.miles > 0 && orgOverall ?
                        <div className="font-bold text-lg">
                            #{orgOverall}
                        </div> :
                        <Tooltip title="Leaderboards generate every hour, check back next hour for updated results!">
                            <div className="font-bold">--</div>
                        </Tooltip>
                    }

                </div>
                <div className="col-span-full md:col-span-1 md:pl-6 flex items-center">
                    <div>
                        <Groups fontSize="large" className="mr-2" />
                    </div>
                    {groupName ?
                        <>
                            <div className="flex-1">
                                {groupName || "Group Name"}
                            </div>
                            <div className="font-bold text-lg">
                                {event?.registration?.totals?.miles > 0 && groupOverall ?
                                    `#${groupOverall}` :
                                    <Tooltip title="Leaderboards generate every hour, check back next hour for updated results!">
                                        <div className="font-bold">--</div>
                                    </Tooltip>
                                }
                            </div>
                        </> :
                        <div>
                            No group registration.
                        </div>
                    }

                </div>
            </div>
            <div>
                <div className="flex justify-center mt-4">
                    <TrackableButton eventName="dashboard_view_org_leaderboard_click" onClick={() => setShowOrgLeaderboard(orgId)}>
                        <Leaderboard className="mr-1" /> View Org Leaderboard
                    </TrackableButton>
                </div>
            </div>
            <HCDialog
                open={Boolean(showOrgLeaderboard)}
                title={<></>}
                actions={[
                    { label: 'Done', onClick: () => setShowOrgLeaderboard(null) },
                ]}
                onClose={() => setShowOrgLeaderboard(null)}>
                <OrganizationLeaderboard initialEventId={eventId || ""} orgId={showOrgLeaderboard || ""} />
            </HCDialog>
        </div>
    )
};

// interface StandingProps {
//     label: string;
//     rank?: number;
// }

// const Standing: React.FC<StandingProps> = ({ label, rank }) => {
//     return (
//         <div className={cx( "flex-1 flex flex-col items-center", {
//             // "hidden": !rank || rank < 0
//         })}>
//             <div className="text-xs font-bold uppercase">
//                 {label}
//             </div>
//             <div className="text-xl">
//                 {rank !== undefined && rank > -1 ? `#${rank + 1}` : "--"}
//             </div>
//         </div>
//     )
// }

export default EventStandings;
