import React from 'react';
import {CardMedia} from "@mui/material";

interface Props {
    bgImgUrl: string;
    imgUrl: string;
    imgAlt: string;
}

const EventHeader: React.FC<Props> = ({ bgImgUrl, imgUrl, imgAlt }) => {
    return (
        <CardMedia>
            <div className="bg-cover bg-no-repeat bg-center relative" style={ bgImgUrl ? { backgroundImage: `url(${process.env.REACT_APP_ADMIN_URL}/media/${bgImgUrl})`} : {}}>
                <div className="absolute inset-0 bg-[rgba(0,0,0,0.2)]" />
                <div className="flex justify-center relative w-full py-2">
                    <div className="flex items-center h-40">
                        {imgUrl &&
                            <img src={`${process.env.REACT_APP_ADMIN_URL}/media/${imgUrl}`} className="max-w-[240px] max-h-36" alt={imgAlt} />
                        }
                    </div>
                </div>
            </div>
        </CardMedia>
    )
};

export default EventHeader;