import React, {useRef} from 'react';
import {Form, Formik} from "formik";
import {object, string} from "yup";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import TextField from "@mui/material/TextField";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";

import CustomFormikField from "../../_common/formik/customField";
import ActivityTypeSelect from "../../_common/select/activityType";
import HCDialog from "../../_common/dialog";
import { logActivity } from "../../../redux/events";

interface Props {
    open: boolean;
    onClose: () => void;
}

const LogActivityDialog: React.FC<Props> = ({ open, onClose }) => {
    const logActivitySubmitRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const onLogActivity = (values: any) => {
        // @ts-ignore
        dispatch(logActivity(values, enqueueSnackbar));
        onClose();
    }

    return (
        <HCDialog
            open={open}
            title="Log Activity"
            actions={[
                {label: 'Cancel', eventName: "log_activity_dialog_cancel_click", onClick: () => onClose()},
                {label: 'Save', eventName: "log_activity_dialog_save_click", color: 'primary', onClick: () => {
                        logActivitySubmitRef.current?.click();
                }}
            ]}
            onClose={onClose}>
            <div className="w-[250px] md:min-w-[400px]">
                <Formik
                    initialValues={{
                        miles: "",
                        minutes: "",
                        activityTypeId: "",
                        date: new Date(),
                    }}
                    validationSchema={object().shape({
                        activityTypeId: string().required(),
                        miles: string().when('minutes', {
                            is: (minutes: any) => minutes,
                            then: (schema) => schema,
                            otherwise: (schema) => schema.required("Must define minutes or miles"),
                        }),
                        // minutes: string().when('miles', {
                        //     is: (val: any) => val,
                        //     then: (schema) => schema,
                        //     otherwise: (schema) => schema.required("Must define minutes or miles"),
                        // })
                    })}
                    onSubmit={onLogActivity}
                >
                    {({ values, errors, setFieldValue }) => {
                        return (
                            <Form className="py-2">
                                <input type="Submit" className="hidden" ref={logActivitySubmitRef} />
                                <div className="flex">
                                    <div className="flex-1">
                                        {/* @ts-ignore */}
                                        <CustomFormikField
                                            CustomComponent={TextField}
                                            name="miles"
                                            label="Miles"
                                            placeholder="1.2"
                                            type="number"
                                            variant="standard"
                                            fullWidth />
                                    </div>
                                    <div className="mx-4 flex items-center">
                                        -- or --
                                    </div>
                                    <div className="flex-1">
                                        {/* @ts-ignore */}
                                        <CustomFormikField
                                            CustomComponent={TextField}
                                            name="minutes"
                                            label="Minutes"
                                            placeholder="45"
                                            type="number"
                                            variant="standard"
                                            fullWidth />
                                    </div>
                                </div>
                                <div className="my-6">
                                    {/* @ts-ignore */}
                                    <CustomFormikField
                                        name="activityTypeId"
                                        CustomComponent={ActivityTypeSelect} />
                                </div>
                                <div className="hidden md:block">
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={values.date}
                                        onChange={date => setFieldValue("date", date, false)}
                                        renderInput={(params) => <TextField {...params} variant="standard" fullWidth />}
                                    />
                                </div>
                                <div className="md:hidden">
                                    <MobileDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={values.date}
                                        onChange={date => setFieldValue("date", date, false)}
                                        renderInput={(params) => <TextField {...params}  variant="standard" fullWidth />}
                                    />
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </HCDialog>
    )
};

export default LogActivityDialog;