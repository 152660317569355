import React, {useMemo, useState} from 'react';

import { Dashboard, Leaderboard, Person, FitnessCenter } from "@mui/icons-material";

import HCDialog, { Props } from "../_common/dialog";
import LottieAnimation from "../_common/lottie";
import {useDispatch} from "react-redux";
import {welcomeViewed} from "../../redux/login";

const STEPS = [
    "Meet the new HealthCode!",
    "Dashboard",
    "Leaderboards",
    "My Activity",
    "Profile"
];

const ICONS = [
    Dashboard, Dashboard, Leaderboard, FitnessCenter, Person,
]

interface StepProps {
    onNextClick?: () => void;
    onBackClick?: () => void;
}

const WelcomeDialog: React.FC<Props> = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();

    const onNextClick = () => {
        if (activeStep < STEPS.length - 1) {
            setActiveStep(Math.min(activeStep + 1, STEPS.length - 1));
        } else {
            // @ts-ignore
            dispatch(welcomeViewed());
            props.onClose();
            setActiveStep(0);
        }
    }

    const viewForStep = useMemo(() => {
        switch (activeStep) {
            case 0:
                return <WelcomeStep />
            case 1:
                return <DashboardStep />
            case 2:
                return <LeaderboardStep />
            case 3:
                return <MyActivityStep />
            case 4:
                return <ProfileStep />
        }
    }, [activeStep]);

    const Icon = ICONS[activeStep];

    return (
        <HCDialog
            {...props}
            actions={[
                { label: 'Show me next time', eventName: "welcome_show_me_next_time_click", onClick: props.onClose, color: "inherit" },
                { label: activeStep < STEPS.length - 1 ? "Next" : "Done", eventName: "welcome_next_click", onClick: onNextClick, color: "primary" },
            ]}
            title={
                <div className="text-3xl font-bold text-center py-4 border-solid border-0 border-graylight border-b bg-red flex items-center justify-center">
                    {activeStep > 0 ? <Icon className="mr-2" fontSize="large" /> : null}{STEPS[activeStep]}
                </div>
            }>
            <div className="text-center max-w-[600px] font-os">
                {viewForStep}
            </div>
        </HCDialog>
    )
};

const WelcomeStep: React.FC<StepProps> = ({ onNextClick }) => {
    return (
        <div>
            {/*<div className="text-3xl font-bold text-graydark">*/}
            {/*    Meet the new HealthCode!*/}
            {/*</div>*/}
            <div className="flex justify-center">
                <LottieAnimation animation="welcome" className="w-[200px]" />
            </div>
            <div className="mt-6">
                Things may look a bit different, but rest assured - all you favorite features and then some are waiting for you!
            </div>
        </div>
    )
}

const DashboardStep: React.FC<StepProps> = ({ onNextClick }) => {
    return (
        <div>
            <div>
                The Dashboard is your central hub to all important data and actions. From here you can:
            </div>
            <div>
                <ul className="list-disc text-left mx-4 md:mx-20 mt-6 font-bold">
                    <li>
                        Register for events
                    </li>
                    <li>
                        View progress towards your current goals
                    </li>
                    <li>
                        View Leaderboard data for current events
                    </li>
                    <li>
                        Log Activity
                    </li>
                    <li>
                        Edit/Upgrade your active Registrations
                    </li>
                </ul>
            </div>
            <div className="mt-6 border-solid border-gray-light border">
                <iframe className="sm:h-[300px]" width="100%" src="https://www.youtube.com/embed/K0iuSVuafIw"
                        title="Dashboard" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </div>
    )
}

const LeaderboardStep: React.FC<StepProps> = ({ onNextClick }) => {
    return (
        <div>
            {/*<div className="text-2xl font-bold text-graydark">*/}
            {/*    Leaderboards*/}
            {/*</div>*/}
            <div>
                From the Leaderboards you can view all the same data you've grown accustomed to.
            </div>
            <div className="mt-6 border-solid border-gray-light border">
                <iframe className="sm:h-[300px]" width="100%" src="https://www.youtube.com/embed/mI0a3jeV1kk"
                        title="Leaderboards" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </div>
    )
}

const MyActivityStep: React.FC<StepProps> = ({ onNextClick }) => {
    return (
        <div>
            {/*<div className="text-3xl font-bold text-graydark">*/}
            {/*    My Activity*/}
            {/*</div>*/}
            <div>
                The Activity page is used for managing all things related to your daily activities:
            </div>
            <div>
                <ul className="list-disc text-left mx-4 md:mx-20 mt-6 font-bold">
                    <li>
                        Connect / Disconnect third party trackers
                    </li>
                    <li>
                        Log / Delete Activity
                    </li>
                    <li>
                        View completed (past) events
                    </li>
                    <li>
                        View comprehensive YTD stats
                    </li>
                </ul>
            </div>
            <div className="mt-6 border-solid border-gray-light border">
                <iframe className="sm:h-[300px]" width="100%" src="https://www.youtube.com/embed/bAMEJGaajCA"
                        title="Leaderboards" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </div>
    )
}

const ProfileStep: React.FC<StepProps> = ({ onNextClick }) => {
    return (
        <div>
            {/*<div className="text-2xl font-bold text-graydark">*/}
            {/*    Profile*/}
            {/*</div>*/}
            <div>
                Use your Profile page to update the following:
            </div>
            <div>
                <ul className="list-disc text-left mx-4 md:mx-20 mt-6 font-bold">
                    <li>
                        Edit your contact information
                    </li>
                    <li>
                        Change your profile photo
                    </li>
                    <li>
                        Update your email
                    </li>
                </ul>
            </div>
            <div className="mt-6 border-solid border-gray-light border">
                <iframe className="sm:h-[300px]" width="100%" src="https://www.youtube.com/embed/VDfHF--3qaY"
                        title="Leaderboards" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default WelcomeDialog;