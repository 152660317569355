import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import { configureStore } from "@reduxjs/toolkit";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary } from '@sentry/react';
import { HelmetProvider } from "react-helmet-async";

import rootReducer from './redux';
import Home from "./components/home";
import api from "./api";
import theme from './theme';
import ErrorPage from "./components/error";
import useSetAppContext from "./hooks/useSetAppContext";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { api }
            },
            serializableCheck: false,
        }),
    devTools: process.env.REACT_APP_REDUX_DEV_TOOLS === "true",
});

function App() {
    useSetAppContext();

    return (
        <Router>
            <HelmetProvider>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} autoHideDuration={10000}>
                        <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className="App h-full">
                                    <ErrorBoundary fallback={<ErrorPage />}>
                                        <Home />
                                    </ErrorBoundary>
                                </div>
                            </LocalizationProvider>
                        </ThemeProvider>
                    </SnackbarProvider>
                </Provider>
            </HelmetProvider>
        </Router>
    );
}

export default App;
