import React, {useState} from 'react';
import {Formik, Form} from "formik";
import {object, string} from "yup";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import {initiatePasswordReset} from "../../../redux/login";
import {loggingInSelector} from "../../../redux/login/selectors";
import TrackableButton from "../../_common/buttons/trackable";

const ForgotPassword = ({ setShowForgotPassword }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const loggingIn = useSelector(loggingInSelector);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const onPasswordResetClick = email => {
        dispatch(initiatePasswordReset(email, () => setShowConfirmation(true), enqueueSnackbar))
    }

    return (
        <div>
            <div className="text-lg uppercase text-center mb-2 font-hc tracking-wide">
                Forgot Password
            </div>
            {!showConfirmation ?
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    validationSchema={object().shape({
                        email: string().email("Please enter a valid email").required()
                    })}
                    onSubmit={({ email }) => {
                        onPasswordResetClick(email);
                    }}>
                    <Form>
                        <div className="mb-4 text-center">
                            Enter your email address below to initiate a Password Reset. You will be emailed a link to reset
                            your password.
                        </div>
                        <div className="mb-4">
                            <CustomFormikField
                                name="email"
                                label="Email"
                                placeholder="john.smith@gmail.com"
                                CustomComponent={TextField}
                                variant="standard"
                                fullWidth />
                        </div>
                        {loggingIn ?
                            <div className="flex justify-center">
                                <CircularProgress size={35} />
                            </div> :
                            <>
                                <div className="mb-4 mt-8 flex center">
                                    <TrackableButton eventName="forgot_password_initiate_reset_click" variant="contained" color="primary" type="Submit" fullWidth>
                                        Initiate Reset
                                    </TrackableButton>
                                </div>
                                <div className="flex justify-center">
                                    <TrackableButton eventName="forgot_password_back_click" variant="text" onClick={() => setShowForgotPassword(false)} color="inherit">
                                        Back
                                    </TrackableButton>
                                </div>
                            </>
                        }
                    </Form>
                </Formik> :
                <div>
                    <div className="mb-4">
                        You have been emailed a link to complete the Password Reset.
                    </div>
                    <div>
                        <TrackableButton eventName="forgot_password_complete_click" variant="contained" color="primary" onClick={() => setShowForgotPassword(false)} fullWidth>
                            Got it
                        </TrackableButton>
                    </div>
                </div>
            }

        </div>
    );
};

export default ForgotPassword;