import React from 'react';
import Warning from '@mui/icons-material/Warning';
import {Tooltip} from "@mui/material";
import {ACTIVITY_TYPES} from "../../../../utils";

interface Props {
    totalMiles?: number;
    goal?: number;
    disableEdit?: boolean;
    onEditClick?: () => void;
    onUpgradeClick?: () => void;
    registrationLevel?: string;
    activityTotals?: {
        activityTypeId: number;
        miles: number;
        minutes: number;
        calories: number;
    }[]
}

const COLOR_FOR_ACTIVITY = {
    5: 'bg-[#4dc9f6]',
    6: 'bg-[#f67019]',
    7: 'bg-[#f53794]',
    8: 'bg-[#537bc4]',
    9: 'bg-[#acc236]',
    10: 'bg-[#166a8f]',
    11: 'bg-[#00a950]',
    12: 'bg-[#58595b]',
    13: 'bg-[#8549ba]',
}

const EventGoal: React.FC<Props> = ({ totalMiles, activityTotals, goal, disableEdit = false, onEditClick, onUpgradeClick, registrationLevel }) => {
    return goal ? (
        <div>
            <div className="flex font-bold items-center">
                <div className="text-sm uppercase">
                    My Goal
                </div>
                <div className="flex-1 flex justify-end">
                    <div>
                        {(totalMiles || 0)?.toFixed(0)} / {goal} miles
                    </div>
                </div>
            </div>
            <div className="h-7 bg-graylight my-2 flex cursor-pointer">
                <div className="flex" style={{width: `${((totalMiles || 0) / goal || 0) * 100}%`}}>
                    {activityTotals?.map(totals => (
                        <Tooltip
                            key={totals.activityTypeId}
                            title={<div className="text-lg">{ACTIVITY_TYPES[totals.activityTypeId]} {totals.miles.toFixed(2)} miles</div>}
                            enterTouchDelay={0}
                            enterDelay={0}>
                            {/* @ts-ignore*/}
                            <div className={`h-7 ${COLOR_FOR_ACTIVITY[totals.activityTypeId]}`} style={{ width: `${Math.min((totals.miles || 0) / (totalMiles || 0) * 100, 100)}%` }} />
                        </Tooltip>
                    ))}
                </div>
            </div>
        </div>
    ) : (
        <div className="flex flex-col items-center text-center">
            <div className="text-lg">
                <Warning fontSize="large" />
                <div className="font-bold mb-1">
                    No mile goal set
                </div>
            </div>
            <div>
                Edit your registration below to set a goal and track your progress.
            </div>
        </div>
    )
}

export default EventGoal;