import React from 'react';
import {Dashboard, Leaderboard, Person, FitnessCenter, AddComment, NewReleases} from "@mui/icons-material";
import {NavLink, NavLinkProps, Route, Routes} from "react-router-dom";
import cx from 'classnames';
import {useSelector} from "react-redux";
import {loggedInSelector} from "../../redux/login/selectors";
import api from "../../api";

interface Props {
    onItemClick?: () => void;
    setShowWelcome?: (show: boolean) => void;
}

const Nav: React.FC<Props> = ({ onItemClick, setShowWelcome }) => {
    const loggedIn = useSelector(loggedInSelector);

    return (
        <div>
            <Routes>
                <Route path="*" element={<NavItem icon={<Dashboard />} label="Dashboard" to="/dashboard" onItemClick={onItemClick} />} />
                <Route path="/events" element={<NavItem icon={<Dashboard />} label="Dashboard" to="/events" onItemClick={onItemClick} />} />
                <Route path="/register" element={<NavItem icon={<Dashboard />} label="Dashboard" to="/register" onItemClick={onItemClick} />} />
            </Routes>
            <NavItem icon={<Leaderboard />} label="Leaderboards" to="/leaderboards" onItemClick={onItemClick} />
            {loggedIn &&
                <>
                    <NavItem icon={<FitnessCenter />} label="My Activity" to="/activity" onItemClick={onItemClick} />
                    <NavItem icon={<Person />} label="Profile" to="/profile" onItemClick={onItemClick} />
                </>
            }
            <NavItem icon={<AddComment />} label="Feedback" to="/feedback" onItemClick={onItemClick} />
            <div className="flex items-center p-4 cursor-pointer" onClick={() => setShowWelcome ? setShowWelcome(true) : null}>
                <div className="mr-2">
                    <NewReleases />
                </div>
                <div>
                    App Intro
                </div>
            </div>
        </div>
    )
};

interface NavItemProps extends NavLinkProps {
    icon: any;
    label: string;
    onItemClick?: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ icon, label, to, onItemClick }) => {
    return (
        <NavLink to={to} onClick={() => {
            api.logEvent("nav_item_click", { label });
            if (onItemClick) {
                onItemClick()
            }
        }} className={({ isActive }) => cx({
            "text-red": isActive
        })}>
            <div className="flex items-center p-4 cursor-pointer">
                <div className="mr-2">
                    {icon}
                </div>
                <div>
                    {label}
                </div>
            </div>
        </NavLink>
    )
}

export default Nav;
