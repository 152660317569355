import React, {useEffect, useState, useRef, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Card,
    CardContent,
    CardMedia,
    Stepper,
    Step,
    StepLabel,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    TextField, IconButton,
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {useSnackbar} from "notistack";
import {isBefore} from "date-fns";
import {useNavigate} from "react-router-dom";
import cx from "classnames";
import numeral from "numeral";
import {FacebookShareButton} from "react-share";

import { eventSelector } from "../../../../redux/events/selectors";
import {
    checkout,
    fetchActiveRegistrations,
    fetchEvent
} from "../../../../redux/events";
import LoadingView from "../../../_common/loading";
import ShirtSizeSelect from "../../../_common/select/shirtSize";
import GroupSelect from "../../../_common/select/group";
import useStripe from "../../../../hooks/useStripe";
import {loggedInSelector, profileSelector, userIdSelector} from "../../../../redux/login/selectors";
import LoginDialog from "../../../login/dialog";
import RegistrationLevel from "../../../_common/text/registrationLevel";
import LottieAnimation from "../../../_common/lottie";
import EventHeader from "../../../_common/events/header";
import ServerHTML from "../../../_common/text/serverHtml";
import OrganizationAutocomplete from "../../../_common/select/organization/autocomplete";
import Label from "../../../_common/text/label";
import TrackableButton from "../../../_common/buttons/trackable";
import Profile from "../../../profile";

import fbLogo from "../../../../img/Facebook_Logo_Secondary.png";
import {Helmet} from "react-helmet-async";
import {CopyAll} from "@mui/icons-material";

interface Props {
    eventId: string | number;
    onDismiss: () => void;
}

const steps = ['Choose Package', 'Registration Information', 'Complete'];
const steps_with_profile = ['Choose Package', 'Registration Info', 'Leaderboard Info', 'Complete'];
const ADD_ON_FEE = 4.99;

const EventRegistration: React.FC<Props> = ({ eventId, onDismiss }) => {
    const [amount, setAmount] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [selectedPackage, setSelectedPackage] = useState<any|null>(null);
    const event = useSelector(eventSelector(eventId));
    const userId = useSelector(userIdSelector);
    const [goal, setGoal] = useState(event?.registration?.mileGoal || "");
    const [orgId, setOrgId] = useState(event?.registration?.orgId || "");
    const [groupId, setGroupId] = useState(event?.registration?.groupId || "");
    const [groupAccount, setGroupAccount] = useState(event?.registration?.groupAccount || 0);
    const [payAddOnFee, setPayAddOnFee] = useState(false);
    const [shirtSize, setShirtSize] = useState(event?.registration?.shirtSize || ''); // Default to empty to trigger validation
    const [showLogin, setShowLogin] = useState(false);
    const [stripeToken, setStripeToken] = useState<string|undefined>(undefined);
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [processingCheckout, setProcessingCheckout] = useState(false);
    const [shirtError, setShirtError] = useState(false);
    const submitRef = useRef<HTMLDivElement>(null);
    const loggedIn = useSelector(loggedInSelector);
    const profile = useSelector(profileSelector);
    const [showProfile] = useState(!profile?.firstName);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const onCheckout = useCallback((amount: any, token: any) => {
        if (!processingCheckout) {
            setProcessingCheckout(true);
            // @ts-ignore
            dispatch(checkout(event.id, amount, token, selectedPackage.id, selectedPackage.name, selectedPackage.color, goal, orgId, groupId, groupAccount, selectedPackage.receives_shirt ? shirtSize : null, enqueueSnackbar, success => {
                setProcessingCheckout(false);
                if (success) {
                    setActiveStep(a => a + 1);
                }
            }));
        }
    }, [dispatch, enqueueSnackbar, event?.id, goal, orgId, groupId, selectedPackage?.id, selectedPackage?.name, selectedPackage?.color, selectedPackage?.receives_shirt, groupAccount, shirtSize, processingCheckout])

    useEffect(() => {
        if (!event && eventId) {
            // @ts-ignore
            dispatch(fetchEvent(eventId, enqueueSnackbar));
        }
    }, [event, eventId, dispatch, enqueueSnackbar]);

    // This handles login during register
    // TODO: This is probably causing the double checkouts
    useEffect(() => {
        if (loggedIn && selectedPackage && showLogin && userId) {
            // setProcessingCheckout(true);
            setShowLogin(false);
            // @ts-ignore
            dispatch(fetchActiveRegistrations(userId, enqueueSnackbar));
            // Checkout with the cached amount and token
            onCheckout(paymentAmount, stripeToken);
        }
    }, [loggedIn, selectedPackage, showLogin, onCheckout, paymentAmount, stripeToken, userId, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (event && (!event.allowRegistration || isBefore(event.displayEndDate, new Date()))) {
            navigate(-1);
        }
    }, [event, event?.allowRegistration, event?.displayEndDate, navigate]);

    const visibleOptions = useMemo(() => {
        if (event?.registration) {
            return event?.registrationOptions.filter((option: any) => option.price > event.registration.registrationLevelPrice);
        }
        return event?.registrationOptions;
    }, [event?.registration, event?.registrationOptions]);

    // TODO: Put this back when we can figure it out, I bet this doesn't affect very many peeps
    // useEffect(() => {
    //     if (!processingCheckout && selectedPackage && visibleOptions?.indexOf(selectedPackage) === -1 && activeStep !== 0) {
    //         setSelectedPackage(null);
    //         setActiveStep(0);
    //     }
    // }, [visibleOptions, selectedPackage, activeStep, processingCheckout]);

    const handleOrgChange = (event: SelectChangeEvent<unknown>) => {
        setOrgId(event.target.value as string);
    }

    const handleGroupChange = (event: SelectChangeEvent<unknown>) => {
        setGroupId(event.target.value as string);
    }

    const onSubmit = (paymentAmount: number, token?: string) => {
        if (!loggedIn) {
            setStripeToken(token);
            setPaymentAmount(paymentAmount);
            setShowLogin(true);
        } else {
            onCheckout(paymentAmount, token);
        }
    }

    const onPackageSelected = (option: any) => {
        if (!selectedPackage) {
            setSelectedPackage(option);
            setActiveStep(activeStep + 1);
            if (option.price > 0) {
                setAmount("");
            }
            submitRef.current?.scrollIntoView({behavior: "smooth"})
        } else {
            setSelectedPackage(null);
            setActiveStep(activeStep - 1);
        }
    };

    return (
        <div className="h-full bg-graylight md:px-6" >

            {!event ?
                <LoadingView title="Loading event..." /> :
                <>
                    <Helmet prioritizeSeoTags>
                        <title>{event.name}</title>
                        <meta property="og:title" content={event.name} />
                        <meta property="og:title" content={event.name} />
                        <meta property="og:description" content={`Register now for ${event.name}!`} />
                        <meta property="og:url" content={document.location.href} />
                        <meta property="og:image" content={event.imageUrls.event} />
                    </Helmet>
                    <Card className="mb-8 mt-2 md:mt-4">
                        <EventHeader bgImgUrl={event?.imageUrls.leaderboard || ""} imgUrl={event?.imageUrls.event || ""} imgAlt={event?.name} />
                        <CardContent className="flex flex-col md:flex-row">
                            <div className="flex-1 pt-2 md:pt-0">
                                {!event.registration ?
                                    <ServerHTML dangerouslySetInnerHTML={{__html: event.html.register}}/> :
                                    <div>
                                        <div className="text-lg font-bold mb-6">
                                            <span className="pr-2">Upgrade your</span>
                                            <RegistrationLevel color={event.registration.registrationLevelColor} label={event.registration.registrationLevelName} />
                                            <span className="pl-2">registration</span>
                                        </div>
                                        <ServerHTML dangerouslySetInnerHTML={{__html: event.html.register}}/>
                                    </div>
                                }
                            </div>
                        </CardContent>
                    </Card>
                    {!visibleOptions.length && activeStep === 0 ?
                        <div className="text-center">
                            <div className="font-hc text-center text-2xl tracking-wider">
                                Congrats!
                            </div>
                            <LottieAnimation animation="congrats" className="h-48" />
                            <div className="font-bold">
                                You're already registered at the top Level!
                            </div>
                            <FacebookShareMessage event={event} />
                        </div> :
                        <div>
                            <div className="my-10 md:mx-10 w-full md:w-auto">
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {(!showProfile ? steps : steps_with_profile).map((label, index) => {
                                        const stepProps: { completed?: boolean } = {};
                                        const labelProps: {
                                            optional?: React.ReactNode;
                                        } = {};
                                        // if (isStepOptional(index)) {
                                        //     labelProps.optional = (
                                        //         <Typography variant="caption">Optional</Typography>
                                        //     );
                                        // }
                                        stepProps.completed = activeStep > index;
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </div>
                            {activeStep < 2 &&
                                // <div className="flex space-y-4 md:space-y-0 md:space-x-8 md:pb-20 justify-center flex-col md:flex-row">
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                                    {visibleOptions.map((option: any) => (
                                        <Card key={option.id} className={cx("flex flex-col transition-all h-auto", {
                                            "hidden": selectedPackage && selectedPackage.id !== option.id,
                                            "flex-1": !selectedPackage
                                        })}>
                                            <CardMedia>
                                                <div className={`text-white text-center text-lg py-3 ${bgClass(option.color)}`}>
                                                    {option.name}
                                                </div>
                                                <div
                                                    className={`text-center text-[2rem] border-0 border-solid border-b border-graylight py-2 ${fontWeightClass(option.color)}`}>
                                                    {option.price > 0 ?
                                                        numeral(option.price - (event.registration?.registrationLevelPrice || 0)).format("$0,0") :
                                                        <div className="text-lg font-normal my-3">
                                                            {event.registration_level_free_price_text || "Pay what you can"}
                                                        </div>
                                                    }
                                                </div>
                                            </CardMedia>
                                            <CardContent className="text-center text-gray">
                                                <div dangerouslySetInnerHTML={{__html: option.blurb}}/>
                                            </CardContent>
                                            <div className="border-0 border-solid border-t border-graylight p-3">
                                                {option.price === 0 &&
                                                    <div className="flex flex-col items-center px-3">
                                                        <div className="mb-2">
                                                            Enter amount:
                                                        </div>
                                                        <div className="w-full">
                                                            <TextField
                                                                name="amount"
                                                                value={amount}
                                                                type="number"
                                                                placeholder="$0.00"
                                                                onChange={e => setAmount(e.target.value)}
                                                                fullWidth/>
                                                        </div>
                                                    </div>
                                                }
                                                <ul className="list-disc px-8 text-left">
                                                    {option.items.filter((item: string) => item).map((item: string) => (
                                                        <li key={item} className="my-2">{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="flex justify-center my-3 flex-1 items-end pb-4">
                                                <div
                                                    className="flex justify-center w-full border-0 border-t border-solid border-graylight pt-6">
                                                    <div className="lg:w-[75%]">
                                                        <TrackableButton eventName={selectedPackage ? "event_registration_change_package_click" : "event_registration_select_package_click"} variant="contained" color="inherit" onClick={() => onPackageSelected(option)} fullWidth>
                                                            {selectedPackage ? "Change Package" : "Select"}
                                                        </TrackableButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    ))}
                                    {activeStep === 1 &&
                                        <div className="flex-1 md:col-span-3">
                                            <div className="text-sm font-bold uppercase mb-1">
                                                Registration Info
                                            </div>
                                            <Card className="flex-1">
                                                <CardContent>
                                                    <div>
                                                        <TextField
                                                            name="goal"
                                                            value={goal}
                                                            onChange={e => setGoal(e.target.value)}
                                                            label="Mile Goal"
                                                            placeholder="15"
                                                            variant="standard"
                                                            type="number" />
                                                    </div>
                                                    <div className="my-4">
                                                        <OrganizationAutocomplete
                                                            name="orgId"
                                                            onChange={handleOrgChange}
                                                            variant="standard"
                                                            value={orgId}
                                                            groupAccountChecked={groupAccount === 1}
                                                            onGroupAccountChange={(checked: boolean) => setGroupAccount(checked ? 1 : 0)} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <GroupSelect orgId={orgId} onChange={handleGroupChange} variant="standard" value={groupId} />
                                                    </div>
                                                </CardContent>
                                            </Card>
                                            {selectedPackage?.receives_shirt === 1 &&
                                                <div className="mt-6">
                                                    <Label>
                                                        Shirt Info
                                                    </Label>
                                                    <Card>
                                                        <CardContent>
                                                            <div>
                                                                <div>
                                                                    AGREE TO PAY FEES
                                                                </div>
                                                                <div>
                                                                    {/* TODO: This should eventually be server-driven */}
                                                                    <FormControlLabel control={<Checkbox checked={payAddOnFee} onChange={e => setPayAddOnFee(e.target.checked)} />} label={`${numeral(ADD_ON_FEE).format("$0,0.00")} shipping and handling`} />
                                                                </div>
                                                                <div className="text-xs">
                                                                    Paying these tax-deductible fees directs more funds in support of HealthCode’s programs and mission to promote health.
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 flex">
                                                                <ShirtSizeSelect
                                                                    value={shirtSize}
                                                                    onChange={e => setShirtSize(e.target.value as string)}
                                                                    error={Boolean(shirtError)} />
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            }
                                            <PaymentInfo
                                                amount={amount || (selectedPackage.price - (event.registration?.registrationLevelPrice || 0))}
                                                selectedPackage={selectedPackage}
                                                onSubmit={onSubmit}
                                                paymentDisabled={processingCheckout}
                                                payAddOnFee={payAddOnFee}
                                                validateShirtSize={() => {
                                                    const needsShirt = selectedPackage?.receives_shirt && !shirtSize;
                                                    setShirtError(needsShirt);
                                                    return !needsShirt;
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            {((activeStep === 2 && !showProfile) || activeStep === 3) &&
                                <div className="text-center">
                                    <div className="text-center text-2xl font-hc mt-2">
                                        Registration Complete
                                    </div>
                                    <LottieAnimation animation="celebrate-jump" className="h-40 py-4" />
                                    <div className="text-lg mt-4">
                                        Congrats! Your <RegistrationLevel color={event.registration?.registrationLevelColor || "bronze"} label={event.registration?.registrationLevelName || "Bronze"} /> Registration is complete.
                                    </div>
                                    <div className="my-2">
                                        You will receive an email shortly with further instructions.
                                    </div>
                                    <FacebookShareMessage event={event} />
                                    <div className="py-8 flex justify-center">
                                        <TrackableButton eventName="event_registration_done_click" variant="contained" onClick={onDismiss} className="w-[400px]">
                                            Done
                                        </TrackableButton>
                                    </div>
                                </div>
                            }
                            {activeStep === 2 && showProfile &&
                                <Profile onSave={() => setActiveStep(activeStep + 1)} condensed />
                            }
                        </div>
                    }
                </>
            }
            <LoginDialog open={showLogin} onClose={() => setShowLogin(false)} registerDefault />
            <div ref={submitRef} className="pb-8" />
        </div>
    )
};

interface PaymentInfoProps {
    amount: string | number;
    selectedPackage: any;
    onSubmit: (amount: number, token?: string) => void;
    paymentDisabled?: boolean;
    payAddOnFee: boolean;
    ref?: React.Ref<HTMLDivElement>;
    validateShirtSize: () => boolean;
}

const PaymentInfo: React.FC<PaymentInfoProps> = React.forwardRef(({ amount, selectedPackage, onSubmit, paymentDisabled = false, payAddOnFee, validateShirtSize }, ref) => {
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [cardError, setCardError] = useState<any|null>(null);
    const { client, card }: any = useStripe('#card-element');

    const floatAmount = parseFloat(amount.toString()) + (payAddOnFee ? ADD_ON_FEE : 0);

    const handleSubmit = async () => {
        if (validateShirtSize()) {
            if (floatAmount > 0) {
                setDisableSubmit(true);
                const result = await client.createToken(card);
                setDisableSubmit(false);
                if (result.error) {
                    // TODO: Handle Error
                    console.error(result.error);
                    setCardError(result.error);
                } else {
                    onSubmit(floatAmount, result.token.id);
                }
            } else {
                onSubmit(floatAmount);
            }
        }
    }
    return (
        <div>
            <div className={cx({
                "hidden": floatAmount === 0
            })}>
                <div className="text-sm font-bold uppercase mb-1 mt-6">
                    Payment Info
                </div>
                <Card className="mb-4">
                    <CardContent>
                        <div className="flex">
                            <div className="flex-1">
                                <div>
                                    {selectedPackage.name}
                                </div>
                                {payAddOnFee &&
                                    <div>
                                        Shirt Shipping & Handling
                                    </div>
                                }
                            </div>
                            <div className="mr-20 text-right">
                                <div>
                                    x 1
                                </div>
                                {payAddOnFee &&
                                    <div>
                                        x 1
                                    </div>
                                }
                                <div className="font-bold mt-2">
                                    Total
                                </div>
                            </div>
                            <div className="text-right">
                                <div>
                                    {numeral(amount).format("$0,0.00")}
                                </div>
                                {payAddOnFee &&
                                    <div>
                                        {numeral(ADD_ON_FEE).format("$0,0.00")}
                                    </div>
                                }
                                <div className="font-bold mt-2">
                                    {numeral(floatAmount).format("$0,0.00")}
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card className="flex-1">
                    <CardContent>
                        <div id="card-element" />
                    </CardContent>
                </Card>
                {cardError &&
                    <div className="text-red italic text-sm mt-1">
                        {cardError.message}
                    </div>
                }
            </div>
            <div className="mt-6 flex justify-center" ref={ref}>
                {disableSubmit || paymentDisabled ?
                    <CircularProgress size={30}/> :
                    <div className="md:min-w-[350px]">
                        <TrackableButton eventName="event_registration_submit_click" variant="contained" color="primary" onClick={handleSubmit} disabled={disableSubmit || paymentDisabled} fullWidth>
                            Submit Order
                        </TrackableButton>
                    </div>
                }
            </div>
        </div>
    )
});

interface FacebookShareMessageProps {
    event: {
        name: string;
    };
}

const FacebookShareMessage: React.FC<FacebookShareMessageProps> = ({ event }) => {
    const { enqueueSnackbar } = useSnackbar();

    return event ? (
        <div className="mt-4 flex flex-col items-center">
            <div className="font-bold">
                Let your Facebook network know and invite them to join you.
            </div>
            <div className="my-4">
                <FacebookShareButton url={document.location.href}>
                    <TrackableButton eventName="event_registration_facebook_share_click" variant="contained" color="primary">
                        <img className="h-5 w-5 mr-2" src={fbLogo} alt="facebook" /> Share Your Registration
                    </TrackableButton>
                </FacebookShareButton>
            </div>
            <div className="font-bold">
                Need some inspiration?
            </div>
            <div className="flex items-center mb-2">
                <div>
                    Copy + Paste the message below
                </div>
                <div className="ml-2">
                    <IconButton onClick={() => {
                        const text = `Join Me in Getting Active and Healthy! 🌍🏃️\n\nI've just registered for ${event.name}, a global virtual event dedicated to helping us live more active, healthy, and connected lives. Whether you're into yoga, running, or just looking to get moving, this free event is for you! Click below to join me.`;
                        navigator.clipboard.writeText(text)
                            .then(() => enqueueSnackbar('Text Copied!', {variant: 'success'}))
                    }}>
                    <CopyAll />
                    </IconButton>
                </div>
            </div>
            <div className="p-4 border border-solid border-gray rounded-md max-w-[500px] mb-4">
                Join Me in Getting Active and Healthy! 🌍🏃️<br/><br/>
                I've just registered for {event.name}, a global virtual event dedicated to helping us live more active,
                healthy, and connected lives. Whether you're into yoga, running, or just looking to get moving, this
                free event is for you! Click below to join me.
            </div>
            <div>
                Let's inspire each other to stay healthy and connected.
            </div>
            <div className="mt-2">
                Thank you!
            </div>
        </div>
    ) : null;
}

export const bgClass = (color: string) => cx({
    "bg-gold": color === "gold",
    "bg-silver": color === "silver",
    "bg-bronze": color === "bronze",
    "bg-platinum": color === "platinum",
});

const fontWeightClass = (color: string) => cx({
    "font-bold": ["gold", "platinum"].includes(color)
});

export default EventRegistration;
