import React, { forwardRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

const GenderSelect: React.FC<SelectProps> = forwardRef((props, ref) => {
    return (
        <div>
            <FormControl fullWidth error={props.error}>
                <InputLabel id="gender-label" variant="standard">Gender</InputLabel>
                {/* @ts-ignore */}
                <Select
                    labelId="gender-label"
                    id="gender-select"
                    label="Gender"
                    name="gender"
                    variant="standard"
                    {...props}
                    // @ts-ignore
                    // onChange={e => setFieldValue("gender", e.target.value, false)}
                >
                    <MenuItem value="M">Male</MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                    <MenuItem value="N">Non-Binary</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
});

export default GenderSelect;
