/*global Stripe*/
import {useState, useEffect} from 'react';

const useStripe = (cardElementId) => {
    const [stripe, setStripe] = useState({});
    useEffect(() => {
        const client = Stripe(process.env.REACT_APP_STRIPE_API_KEY);

        // Create an instance of Elements.
        const elements = client.elements();

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        const style = {
            base: {
                color: '#32325d',
                lineHeight: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        const card = elements.create('card', {style});
        card.mount(cardElementId);

        setStripe({ client, card });
    }, [cardElementId]);

    return stripe;
};

export default useStripe;