import React, { useState } from 'react';
import {ButtonProps, CircularProgress} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import { useSnackbar } from "notistack";

import { mmfOauth, disconnectMmf } from "../../../../redux/login";
import { profileSelector } from "../../../../redux/login/selectors";

import mmrLogo from "../../../../img/trackers/map_my_run_logo_white.svg";
import mmrLogoDark from "../../../../img/trackers/map_my_run_logo_dark.svg";
import HCDialog from "../../dialog";
import TrackableButton from "../trackable";

// TODO: Select authToken from profile to display "linked" state
const MmfAuthButton: React.FC<ButtonProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const profile = useSelector(profileSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const connected = profile?.mmf.accessToken;

    const onAuthClick = () => {
        setLoading(true);
        // @ts-ignore
        dispatch(mmfOauth(setLoading, enqueueSnackbar));
    }

    const onDisconnect = () => {
        // @ts-ignore
        dispatch(disconnectMmf(enqueueSnackbar));
        setOpen(false);
    }

    return (
        <div className="h-12 flex items-center justify-center">
            {loading ?
                <CircularProgress size={30} className="a" /> :
                <TrackableButton eventName={connected ? "mmf_disconnect_click" : "mmf_connect_click"} variant="contained" {...props} onClick={connected ? () => setOpen(true) : onAuthClick} color={connected ? "inherit" : "primary"}>
                    {connected ? "Disconnect" : "Connect"} <img src={connected ? mmrLogoDark : mmrLogo} alt="MapMyRun" className="h-3 ml-3" />
                </TrackableButton>
            }
            <HCDialog
                open={open}
                title="Disconnect MapMyRun"
                actions={[
                    { label: 'Cancel', eventName: "mmf_disconnect_dialog_cancel_click", onClick: () => setOpen(false) },
                    { label: 'Disconnect', eventName: "mmf_disconnect_dialog_disconnect_click", onClick: onDisconnect },
                ]}
                onClose={() => setOpen(false)}>
                Are you sure you want to disconnect MapMyRun?
            </HCDialog>
        </div>
    );
}

export default MmfAuthButton;