import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Groups, Person} from "@mui/icons-material";
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import { Download, History } from "@mui/icons-material";
import BackIcon from "@mui/icons-material/ArrowBack";
import {useSnackbar} from "notistack";
import numeral from "numeral";

import {
    groupLeaderboardSelector, groupsSelector,
    organizationsSelector, orgHistorySelector,
    orgLeaderboardSelector
} from "../../../redux/events/selectors";
import {
    exportOrgLeaderboard,
    fetchEventGroupLeaderboard,
    fetchEventOrganizationLeaderboard,
    fetchOrgHistory
} from "../../../redux/events";
import TrackableButton from "../../_common/buttons/trackable";
import TrackableIconButton from "../../_common/buttons/trackable/iconButton";
import LoadingView from "../../_common/loading";

interface Props {
    initialEventId: string | number;
    orgId: string | number;
}

const OrganizationLeaderboard: React.FC<Props> = ({ initialEventId, orgId }) => {
    const [eventId, setEventId] = useState(initialEventId);
    const [groupId, setGroupId] = useState<string|null>(null);
    const [viewBy, setViewBy] = useState("individuals");
    const [showHistory, setShowHistory] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const orgLeaderboard = useSelector(orgLeaderboardSelector(eventId, orgId));
    const groupLeaderboard = useSelector(groupLeaderboardSelector(eventId, groupId));
    const orgs = useSelector(organizationsSelector);
    const groups = useSelector(groupsSelector);
    const orgHistory = useSelector(orgHistorySelector(orgId));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const org = useMemo(() => {
        return orgs?.find((org: any) => org.id.toString() === orgId.toString());
    }, [orgs, orgId]);

    const group = useMemo(() => {
        return groups?.find((group: any) => group.id.toString() === groupId?.toString());
    }, [groups, groupId]);

    useEffect(() => {
        if (orgId && eventId && !orgLeaderboard) {
            // @ts-ignore
            dispatch(fetchEventOrganizationLeaderboard(eventId, orgId, enqueueSnackbar));
        }
    });

    useEffect(() => {
        if (groupId && eventId && !groupLeaderboard) {
            // @ts-ignore
            dispatch(fetchEventGroupLeaderboard(eventId, groupId, enqueueSnackbar));
        }
    });

    useEffect(() => {
        orgLeaderboard?.groups?.forEach((group: any) => {
            // @ts-ignore
            dispatch(fetchEventGroupLeaderboard(eventId, group.organization_group_id, enqueueSnackbar));
        })
    }, [orgLeaderboard, eventId, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (orgId) {
            // @ts-ignore
            dispatch(fetchOrgHistory(orgId, enqueueSnackbar));
        }
    }, [dispatch, enqueueSnackbar, orgId]);

    const onDownloadClick = () => {
        setDownloading(true);
        // @ts-ignore
        dispatch(exportOrgLeaderboard(eventId.toString(), orgId.toString(), setDownloading, enqueueSnackbar));
    }

    return (
        <div>
            <div className="font-hc text-2xl text-center mb-2 relative">
                {(group || showHistory) &&
                    <div className="absolute inset-0 flex">
                        <div>
                            <TrackableIconButton eventName="org_leaderboard_back_click" onClick={() => {
                                setGroupId(null);
                                setShowHistory(false);
                            }}>
                                <BackIcon htmlColor="#000000" />
                            </TrackableIconButton>
                        </div>
                    </div>
                }
                <div className="relative flex items-center text-center justify-center pointer-events-none">
                    {org?.name}
                </div>
            </div>
            {group &&
                <div>
                    <div className="font-hc text-lg text-center mb-2">
                        {group.name}
                    </div>
                    <GroupTable records={groupLeaderboard} />
                </div>
            }
            {showHistory &&
                <div>
                    <HistoryTable records={orgHistory || []} setEventId={eventId => {
                        setEventId(eventId);
                        setShowHistory(false);
                    }} />
                </div>
            }
            {!group && !showHistory &&
                <div>
                    <div className="flex justify-center relative">
                        <ToggleButtonGroup
                            value={viewBy}
                            exclusive
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    setViewBy(newValue);
                                }
                            }}
                            aria-label="text alignment">
                            <ToggleButton value="individuals" aria-label="centered">
                                <Person fontSize="medium" className="mr-2" /> Individuals
                            </ToggleButton>
                            <ToggleButton value="groups" aria-label="left aligned">
                                <Groups fontSize="medium" className="mr-2" /> Groups
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {!orgLeaderboard &&
                        <LoadingView />
                    }
                    {viewBy === "groups" &&
                        <ViewByGroupTable records={orgLeaderboard?.groups} setGroupId={setGroupId} />
                    }
                    {viewBy === "individuals" &&
                        <div>
                            <div className="flex justify-center mt-4">
                                <div className="mr-4">
                                    <TrackableButton eventName="org_leaderboard_view_event_history_click" onClick={() => setShowHistory(true)}>
                                        <History className="mr-1"/>View Event History
                                    </TrackableButton>
                                </div>
                                <div>
                                    {downloading ?
                                        <CircularProgress size={30}/> :
                                        <TrackableButton eventName="org_leaderboard_event_download_click" onClick={onDownloadClick}>
                                            <Download className="mr-1"/>Event Download
                                        </TrackableButton>
                                    }
                                </div>
                            </div>
                            <ViewByIndividualTable records={orgLeaderboard?.users} setGroupId={setGroupId} />
                        </div>
                    }
                </div>
            }
        </div>
    );
}

interface ViewByGroupTableProps {
    records: {
        groupName: string;
        mileGoal: number;
        totalMiles: number;
    }[];
    setGroupId: (groupId: string|null) => void;
}

const ViewByGroupTable: React.FC<ViewByGroupTableProps> = ({ records, setGroupId }) => {
    return (
        <TableContainer component={Paper} className="mt-6">
            <Table aria-label="activity table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <strong>Group/Team</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Progress</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>% Towards Goal</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Miles</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records?.map((record, index) => (
                        <TableRow key={`view-by-group-${index}`}>
                            {/* @ts-ignore */}
                            <TableCell className="cursor-pointer" onClick={() => setGroupId(record.organization_group_id || "")}>
                                {record.groupName}
                            </TableCell>
                            <TableCell>
                                <div className="h-3 bg-graylight w-full">
                                    <div className="bg-red h-full" style={{ width: `${record.totalMiles && record.mileGoal ? Math.min((record.totalMiles / record.mileGoal) * 100, 100) : 0}%` }} />
                                </div>
                            </TableCell>
                            <TableCell>
                                {record.totalMiles && record.mileGoal ? Math.min((record.totalMiles / record.mileGoal) * 100, 100)?.toFixed(0) : 0}%
                            </TableCell>
                            <TableCell>
                                {record.totalMiles?.toFixed(2) || 0}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

interface ViewByIndividualTableProps {
    records: {
        first_name: string;
        last_name: string;
        groupName: string;
        mileGoal: number;
        totalMiles: number;
        city: string;
        state: string;
        organization_group_id: string | null;
    }[];
    setGroupId: (groupId: string|null) => void;
}

const ViewByIndividualTable: React.FC<ViewByIndividualTableProps> = ({ records, setGroupId }) => {
    return (
        <TableContainer component={Paper} className="mt-6">
            <Table aria-label="activity table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <strong>Name</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Location</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Group/Team</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Progress</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>% Towards Goal</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Miles</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records?.map((record, index) => (
                        <TableRow key={`view-by-group-${index}`}>
                            <TableCell>
                                {record.first_name}&nbsp;{record.last_name}
                            </TableCell>
                            <TableCell>
                                {record.city},&nbsp;{record.state}
                            </TableCell>
                            <TableCell className="cursor-pointer" onClick={() => setGroupId(record.organization_group_id || "")}>
                                {record.groupName}
                            </TableCell>
                            <TableCell>
                                <div className="h-3 bg-graylight w-full">
                                    <div className="bg-red h-full" style={{ width: `${record.totalMiles && record.mileGoal ? Math.min((record.totalMiles / record.mileGoal) * 100, 100) : 0}%` }} />
                                </div>
                            </TableCell>
                            <TableCell>
                                {record.totalMiles && record.mileGoal ? Math.min((record.totalMiles / record.mileGoal) * 100, 100)?.toFixed(0) : 0}%
                            </TableCell>
                            <TableCell>
                                {record.totalMiles?.toFixed(2) || 0}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

interface GroupTableProps {
    records: {
        first_name: string;
        last_name: string;
        groupName: string;
        mileGoal: number;
        totalMiles: number;
        city: string;
        state: string;
        organization_group_id: string | null;
    }[];
}

const GroupTable: React.FC<GroupTableProps> = ({ records }) => {
    return (
        <TableContainer component={Paper} className="mt-6">
            <Table aria-label="activity table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <strong>Name</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Location</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Progress</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>% Towards Goal</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Miles</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records?.map((record, index) => (
                        <TableRow key={`view-by-group-${index}`}>
                            <TableCell>
                                {record.first_name}&nbsp;{record.last_name}
                            </TableCell>
                            <TableCell>
                                {record.city},&nbsp;{record.state}
                            </TableCell>
                            <TableCell>
                                <div className="h-3 bg-graylight w-full">
                                    <div className="bg-red h-full" style={{ width: `${Math.min((record.totalMiles / record.mileGoal) * 100, 100)}%` }} />
                                </div>
                            </TableCell>
                            <TableCell>
                                {record.totalMiles && record.mileGoal ? Math.min((record.totalMiles / record.mileGoal) * 100, 100)?.toFixed(0) : 0}%
                            </TableCell>
                            <TableCell>
                                {record.totalMiles?.toFixed(2) || 0}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

interface HistoryTableProps {
    records: {
        event_id: number;
        eventName: string;
        numRegistrations: number;
        totalMiles: number | string;
        totalCalories: number | string;
        totalMinutes: number | string;
    }[];
    setEventId: (eventId: string | number) => void;
}

const HistoryTable: React.FC<HistoryTableProps> = ({ records, setEventId }) => {
    const totals = useMemo(() => {
        return records.reduce((ret: any, record: any) => {
            ret.participants += record.numRegistrations;
            ret.miles += numeral(record.totalMiles).value();
            ret.minutes += numeral(record.totalMinutes).value();
            ret.calories += numeral(record.totalCalories).value();
            return ret;
        }, { participants: 0, miles: 0, minutes: 0, calories: 0 });
    }, [records]);

    return (
        <TableContainer component={Paper} className="mt-6">
            <Table aria-label="activity table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <strong>Event</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Participants</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Miles</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Calories</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Minutes</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records?.map((record, index) => (
                        <TableRow key={`view-by-group-${index}`}>
                            <TableCell className="cursor-pointer" onClick={() => setEventId(record.event_id)}>
                                {record.eventName}
                            </TableCell>
                            <TableCell>
                                {numeral(record.numRegistrations).format("0,0")}
                            </TableCell>
                            <TableCell>
                                {numeral(record.totalMiles).format("0,0")}
                            </TableCell>
                            <TableCell>
                                {numeral(record.totalCalories).format("0,0")}
                            </TableCell>
                            <TableCell>
                                {numeral(record.totalMinutes).format("0,0")}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell>
                            <strong>Total</strong>
                        </TableCell>
                        <TableCell>
                            <strong>{numeral(totals.participants).format("0,0")}</strong>
                        </TableCell>
                        <TableCell>
                            <strong>{numeral(totals.miles).format("0,0")}</strong>
                        </TableCell>
                        <TableCell>
                            <strong>{numeral(totals.calories).format("0,0")}</strong>
                        </TableCell>
                        <TableCell>
                            <strong>{numeral(totals.minutes).format("0,0")}</strong>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrganizationLeaderboard;
