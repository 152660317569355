import React from 'react';
import {useSelector} from "react-redux";

import LoadingView from "../_common/loading";
import Leaderboard from "./leaderboard";
import {
    currentEventsSelector,
    leaderboardsLoadingSelector
} from "../../redux/events/selectors";

const Leaderboards: React.FC = () => {
    const leaderboardsLoading = useSelector(leaderboardsLoadingSelector);
    const currentEvents = useSelector(currentEventsSelector);

    return (
        <div>
            {leaderboardsLoading ?
                <LoadingView title="Loading leaderboards..."/> :
                <>
                    {currentEvents.map((event: any) => (
                        <div key={event.id} className="mb-6">
                            <Leaderboard eventId={event.id} registration={event.registration} />
                        </div>
                    ))}
                </>
            }
        </div>
    )
};

export default Leaderboards;