import React, {useMemo} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

const YearSelect: React.FC<SelectProps> = (props) => {
    const years = useMemo(() => {
        const ret = [];
        const currentYear = new Date().getFullYear();
        for (let year = 2014; year <= currentYear; year++) {
            ret.push(year);
        }
        return ret;
    }, []);

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="year-label" variant={props.variant}>Year</InputLabel>
                <Select
                    labelId="year-label"
                    id="year-select"
                    label="Year"
                    {...props}
                    value={props.value || ""}
                >
                    {years.map((year: number) => (
                        <MenuItem key={`year-${year}`} value={year}>{year}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

export default YearSelect;