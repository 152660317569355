import { createSelector } from "@reduxjs/toolkit";
import { isBefore } from "date-fns";

export const eventsLoadingSelector = ({ events }) => events.loading;
export const pastRegistrationsLoadingSelector = ({ events }) => events.pastRegistrationsLoading;
export const leaderboardsLoadingSelector = ({ events }) => events.leaderboardsLoading;
const eventsMapSelector = ({ events }) => events.eventsMap;
const eventsSlugMapSelector = ({ events }) => events.eventsSlugMap;
const registrationMapSelector = ({ events }) => events.registrationMap;
const upcomingEventIdsSelector = ({ events }) => events.upcomingEventIds;
const currentEventIdsSelector = ({ events }) => events.currentEventIds;
const bigTickerMapSelector = ({ events }) => events.bigTickerMap;
const leaderboardsSelector = ({ events }) => events.leaderboards;
export const leaderboardSelector = (eventId) => ({ events }) => events.leaderboards[eventId];
export const orgLeaderboardSelector = (eventId, orgId) => ({ events }) => events.orgLeaderboards[eventId] ? events.orgLeaderboards[eventId][orgId] : undefined;
export const groupLeaderboardSelector = (eventId, groupId) => ({ events }) => events.groupLeaderboards[eventId] ? events.groupLeaderboards[eventId][groupId] : undefined;
// export const eventSelector = (eventId) => ({ events }) => events.eventsMap[eventId];
export const bigTickerSelector = (eventId) => ({ events }) => events.bigTickerMap[eventId];
const orgListSelector = ({ events }) => events.orgs;
const groupListSelector = ({ events }) => events.groups;
export const registrationUpdatingSelector = ({ events }) => events.registrationUpdating;
export const eventRegistrationSelector = (eventId) => ({ events }) => events.registrationMap[eventId];
export const eventStatsSelector = (eventId) => ({ events }) => events.eventStatsMap[eventId];
export const eventUserTotalsMapSelector = ({ events }) => events.eventUserTotalsMap;
export const eventUserTotalsSelector = (eventId) => ({ events }) => events.eventUserTotalsMap[eventId];
export const submittingFeedbackSelector = ({ events }) => events.submittingFeedback;
const ytdStatsSelector = ({ events }) => events.ytdStatsByYear;
export const ytdStatsByYearSelector = (year) => ({ events }) => events.ytdStatsByYear[year];
const orgHistoryMapSelector = ({ events }) => events.orgHistoryMap;

export const organizationsSelector = createSelector(
    [orgListSelector],
    (orgs) => {
        return [...(orgs || [])].sort((org1, org2) => {
            return org1.name.localeCompare(org2.name);
        });
    }
);

export const groupsSelector = createSelector(
    [groupListSelector],
    (groups) => {
        return [...(groups || [])].sort((group1, group2) => {
            return group1.name.localeCompare(group2.name);
        });
    }
);

export const currentEventsSelector = createSelector(
    [currentEventIdsSelector, eventsMapSelector, registrationMapSelector, bigTickerMapSelector, leaderboardsSelector, organizationsSelector, groupsSelector, eventUserTotalsMapSelector],
    (eventIds, eventsMap, registrationMap, bigTickerMap, leaderboardsMap, orgs, groups, eventUserTotalsMap) => {
        return eventIds.map(id => {
            let registration = null;
            if (registrationMap[id]) {
                registration = {
                    ...registrationMap[id],
                    orgName: orgs?.find(org => {
                        return org.id.toString() === registrationMap[id]?.orgId?.toString()
                    })?.name,
                    groupName: groups?.find(group => {
                        return group.id.toString() === registrationMap[id]?.groupId?.toString()
                    })?.name,
                    ...(eventUserTotalsMap[id] || {})
                };
            }

            return {
                ...eventsMap[id],
                registration,
                bigTicker: bigTickerMap[id],
                leaderboard: leaderboardsMap[id],
            }
        })
    }
)

export const eventSelector = eventId => createSelector(
    [eventsMapSelector, registrationMapSelector, organizationsSelector, groupsSelector, eventsSlugMapSelector],
    (eventsMap, registrationMap, orgs, groups, slugMap) => {
        const event = eventsMap[eventId] || slugMap[eventId?.toString()];
        if (event) {
            let registration = null;
            if (registrationMap[event.id]) {
                registration = {
                    ...registrationMap[event.id],
                    orgName: orgs?.find(org => {
                        return org.id.toString() === registrationMap[event.id]?.orgId?.toString()
                    })?.name,
                    groupName: groups?.find(group => {
                        return group.id.toString() === registrationMap[event.id]?.groupId?.toString()
                    })?.name
                };
            }

            return {
                ...event,
                registration,
            }
        }
        return null;
    }
)

export const ytdChartStatsSelector = year => createSelector(
    [ytdStatsSelector],
    (ytdStatsByYear) => {
        const stats = ytdStatsByYear[year];
        if (stats) {
            const statsByActivityTypeId = stats.reduce((ret, stat) => {
                if (ret[stat.activity_type_id]) {
                    ret[stat.activity_type_id][stat.month] = stat;
                } else {
                    ret[stat.activity_type_id] = {
                        [stat.month]: stat
                    }
                }
                return ret;
            }, {});

            return Object.keys(statsByActivityTypeId).map(activityTypeId => {
                return {
                    activityTypeId,
                    data: Array.from(Array(12)).map((_, index) => {
                        return statsByActivityTypeId[activityTypeId][index + 1] || { totalMiles: 0, totalCalories: 0, totalMinutes: 0 }
                    })
                }
            });
        }

        return null;
    }
)

export const upcomingEventsSelector = createSelector(
    [upcomingEventIdsSelector, eventsMapSelector, registrationMapSelector, organizationsSelector, groupsSelector],
    (eventIds, eventsMap, registrationMap, orgs, groups) => {
        return eventIds.map(id => ({
            ...eventsMap[id],
            registration: registrationMap[id] ? {
                ...registrationMap[id],
                orgName: orgs?.find(org => {
                    return org.id.toString() === registrationMap[id]?.orgId?.toString()
                })?.name,
                groupName: groups?.find(group => {
                    return group.id.toString() === registrationMap[id]?.groupId?.toString()
                })?.name
            } : null
        }));
    }
)

export const currentRegisteredEventsSelector = createSelector(
    currentEventsSelector,
    (events) => {
        return events.filter(event => event.registration);
    }
)

export const currentUnregisteredEventsSelector = createSelector(
    currentEventsSelector,
    (events) => {
        return events.filter(event => !event.registration);
    }
)

export const allLeaderboardsSelector = createSelector(
    leaderboardsSelector,
    (leaderboardsMap) => {
        return Object.values(leaderboardsMap);
    }
)

export const pastRegistrationsByYearSelector = (year) => createSelector(
    [registrationMapSelector, leaderboardsSelector, bigTickerMapSelector, eventsMapSelector, organizationsSelector, groupsSelector, eventUserTotalsMapSelector],
    (registrationMap, leaderboardsMap, bigTickerMap, eventsMap, orgs, groups, eventUserTotalsMap) => {
        const now = new Date();
        return Object.values(registrationMap)
            .filter(registration => {
                return isBefore(eventsMap[registration.eventId]?.displayEndDate, now) && eventsMap[registration.eventId]?.endDate.getFullYear().toString() === year.toString()
            })
            .map(registration => {
                return {
                    ...eventsMap[registration.eventId],
                    ...registration,
                    bigTicker: bigTickerMap[registration.eventId],
                    leaderboard: leaderboardsMap[registration.eventId],
                    orgName: orgs?.find(org => {
                        return org.id.toString() === registration.orgId?.toString()
                    })?.name,
                    groupName: groups?.find(group => {
                        return group.id.toString() === registration.groupId?.toString()
                    })?.name,
                    ...(eventUserTotalsMap[registration.eventId] || {})
                }
            })
            .sort((r1, r2) => {
                if (isBefore(r1.startDate, r2.startDate)) {
                    return 1;
                } else if (isBefore(r2.startDate, r1.startDate)) {
                    return -1;
                }
                return 0;
            });
    }
)

export const orgHistorySelector = (orgId) => createSelector(
    [orgHistoryMapSelector],
    (historyMap) => {
        if (historyMap[orgId]) {
            return Object.values(historyMap[orgId])?.reverse();
        }
        return null;
    }
)
