import React from 'react';
import LottieAnimation from "../_common/lottie";
import {AppBar, Toolbar} from "@mui/material";

const ErrorPage: React.FC = () => {
    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>
                    <div className="flex-1 font-extrabold text-xl font-os text-graydark flex items-center">
                        HealthCode
                    </div>
                </Toolbar>
            </AppBar>
            <div className="flex flex-col items-center text-center">
                <div className="py-6">
                    <LottieAnimation animation="error" className="w-[200px]" />
                </div>
                <div className="text-2xl font-bold mb-4">
                    Something went wrong.
                </div>
                <div>
                    We've received a report of the issue and will address ASAP.
                </div>
                <div className="mt-2">
                    Thanks for your understanding!
                </div>
            </div>
        </div>
    )
};

export default ErrorPage;