import React, {useRef, useState} from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useDispatch, useSelector} from "react-redux";
import { useSnackbar } from "notistack";

import EventStandings from "./standings";
import EventGoal from "./goal";
import MmfAuthButton from "../../_common/buttons/mmf";
import GarminAuthButton from "../../_common/buttons/garmin";
import StravaAuthButton from "../../_common/buttons/strava";
import FitbitAuthButton from "../../_common/buttons/fitbit";
import BigTicker from "./bigTicker";
import HCDialog from "../../_common/dialog";
import {Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import GroupSelect from "../../_common/select/group";
import CustomFormikField from "../../_common/formik/customField";
import {updateEventRegistration} from "../../../redux/events";
import LogActivityDialog from "../../activity/logActivityDialog";
import {Link, useNavigate} from "react-router-dom";
import {bgClass} from "./register";
import Upgrade from "@mui/icons-material/Upgrade";
import {Edit, Leaderboard, SyncDisabled} from "@mui/icons-material";
import {registrationUpdatingSelector} from "../../../redux/events/selectors";
import EventHeader from "../../_common/events/header";
import MyStandings from "./myStandings";
import OrganizationAutocomplete from "../../_common/select/organization/autocomplete";
import ShirtSizeSelect from "../../_common/select/shirtSize";
import TrackableButton from "../../_common/buttons/trackable";

interface Props {
    event: {
        id: number;
        name: string;
        slug: string;
        html: {
            event: string;
            register: string;
            registrationComplete: string;
            registerLevel?: string;
        },
        startDate: object;
        endDate: object;
        imageUrls: {
            profile: string;
            leaderboard: string;
            event: string;
            register: string;
        },
        allowRegistration: number;
        registration?: {
            organization_id?: string | number;
            organization_group_id?: string | number;
            orgId: string;
            groupId: string;
            orgName?: string;
            groupName?: string;
            totals: {
                miles: number;
                calories: number;
                minutes: number;
            };
            activityTotals: {
                activityTypeId: number;
                miles: number;
                minutes: number;
                calories: number;
            }[];
            mileGoal?: number;
            userProfileId?: number;
            registrationLevelColor: string;
            registrationLevelName: string;
        };
        bigTicker?: any;
    },
    isActive?: boolean;
    onRegisterClick?: (eventId: string | number) => void;
    className?: string;
}

const EventItem: React.FC<Props> = ({ event, onRegisterClick = () => {}, className = "", isActive }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [showLogActivity, setShowLogActivity] = useState(false);
    const submitRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const registrationUpdating = useSelector(registrationUpdatingSelector);

    const onUpdateRegistration = (info: any) => {
        // @ts-ignore
        dispatch(updateEventRegistration(info, enqueueSnackbar));
        setShowEdit(false);
    }

    const onUpgradeClick = (eventId: any) => {
        navigate(eventId.toString());
    }

    return (
        <>
            <Card className={className}>
                <EventHeader bgImgUrl={event?.imageUrls.leaderboard || ""} imgUrl={event?.imageUrls.event || ""} imgAlt={event?.name} />
                <CardContent>
                    {!event.registration ?
                        <>
                            <div className="mt-1">
                                <div dangerouslySetInnerHTML={{__html: event.html.event}} />
                            </div>
                        </> :
                        <div>
                            <div>
                                <EventGoal activityTotals={event.registration.activityTotals} totalMiles={event.registration.totals?.miles} goal={event.registration.mileGoal} onEditClick={() => setShowEdit(true)} onUpgradeClick={() => onUpgradeClick(event.id)} />
                            </div>
                            <div className="top-divider">
                                <div className="text-sm font-bold uppercase">
                                    Registration Level
                                </div>
                                <div className="flex flex-wrap">
                                    <div className={`font-hc text-white py-2 px-3 md:px-6 mt-2 ${bgClass(event.registration.registrationLevelColor)}`}>
                                        {event.registration.registrationLevelName}
                                    </div>
                                    <div className="flex-1 flex justify-end items-center">
                                        <div className="mr-4">
                                            {registrationUpdating ?
                                                <CircularProgress size={30}/> :
                                                <TrackableButton eventName="dashboard_edit_registration_click" onClick={() => setShowEdit(true)}>
                                                    <Edit className="mr-1"/> Edit
                                                </TrackableButton>
                                            }
                                        </div>
                                        <div className="md:mr-4">
                                            <TrackableButton eventName="dashboard_upgrade_registration_click" onClick={() => onUpgradeClick(event.slug)}>
                                                <Upgrade className="mr-1"/> Upgrade
                                            </TrackableButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {event.registration.totals?.miles === 0 && isActive &&
                                <div className="flex flex-col items-center mt-6 py-6 border-0 border-solid border-t border-graylight text-center">
                                    <div>
                                        <WarningIcon fontSize="large" />
                                    </div>
                                    <div className="text-lg font-bold">
                                        No Activity Logged
                                    </div>
                                    <div className="my-4">
                                        No activity has been recorded for this event.
                                    </div>
                                    <div className="mb-3">
                                        You can link a third party Health Tracker below to automatically log activity in HealthCode or manually add activity above.
                                    </div>
                                    {process.env.REACT_APP_DISABLE_TRACKERS === "true" ?
                                        <div className="text-center mt-3">
                                            <div>
                                                <SyncDisabled fontSize="large" />
                                            </div>
                                            <div>
                                                Third party trackers have been disabled in Prod until the official cut-over on 11/30/2022.
                                            </div>
                                        </div> :
                                        <div className="grid grid-cols-2 gap-4 mt-3">
                                            <div className="col-span-full md:col-span-1">
                                                <MmfAuthButton fullWidth />
                                            </div>
                                            <div className="col-span-full md:col-span-1">
                                                <FitbitAuthButton fullWidth />
                                            </div>
                                            <div className="col-span-full md:col-span-1">
                                                <StravaAuthButton fullWidth />
                                            </div>
                                            <div className="col-span-full md:col-span-1">
                                                <GarminAuthButton fullWidth />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </CardContent>
                {event.registration &&
                    <div>
                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="text-center text-lg font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                    Leaderboard Summary
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <div className="grid grid-cols-2 gap-0">
                                        <div className="col-span-full md:col-span-1 border-0 border-solid border-graylight md:border-r md:pr-6">
                                            <div className="font-bold text-sm uppercase mb-2">
                                                International Stats
                                            </div>
                                            <BigTicker bigTicker={event.bigTicker} />
                                        </div>
                                        <div className="col-span-full md:col-span-1 md:pl-6 pt-6 md:pt-0">
                                            <MyStandings eventId={event.id} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex justify-center mt-4">
                                            <Link to={`leaderboard/${event.id}`}>
                                                <TrackableButton eventName="dashboard_view_full_leaderboard_click">
                                                    <Leaderboard className="mr-1" /> View Full Leaderboard
                                                </TrackableButton>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        {event.registration?.orgName &&
                            <Accordion disableGutters>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="text-center text-lg font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                        My &quot;{event.registration?.orgName}&quot; Summary
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <EventStandings eventId={event.id} orgId={event.registration?.orgId} orgName={event.registration?.orgName} groupId={event.registration?.groupId} groupName={event.registration?.groupName} />
                                </AccordionDetails>
                            </Accordion>
                        }
                    </div>
                }
                {event.allowRegistration === 1 && !event.registration &&
                    <div className="mt-4 py-4 border-0 border-solid border-t border-graylight flex justify-center">
                        <TrackableButton eventName="dashboard_register_for_event_click" color="primary" onClick={() => onRegisterClick(event.slug)} fullWidth>
                            <div className="truncate">
                                <EventAvailableIcon className="mr-1" />Register for {event.name}
                            </div>
                        </TrackableButton>
                    </div>
                }
            </Card>
            {/*<div>*/}
            {/*    <div>*/}
            {/*        {event.registration &&*/}
            {/*            <div>*/}

            {/*                /!*<div className="text-center text-lg font-bold bg-graylight mb-4 font-hc tracking-wider py-1">*!/*/}
            {/*                /!*    Registration Information*!/*/}
            {/*                /!*</div>*!/*/}

            {/*            </div>*/}
            {/*        }*/}
            {/*        <div className="mt-4">*/}
            {/*            <div className="text-center text-lg font-bold bg-graylight mb-4 font-hc tracking-wider py-1">*/}
            {/*                Leaderboard Summary*/}
            {/*            </div>*/}
            {/*            <div className="grid grid-cols-2 gap-0">*/}
            {/*                <div className="col-span-full md:col-span-1 border-0 border-solid border-graylight border-r md:pr-6">*/}
            {/*                    <div className="font-bold text-sm uppercase mb-2">*/}
            {/*                        International Stats*/}
            {/*                    </div>*/}
            {/*                    {event.bigTicker ?*/}
            {/*                        <BigTicker bigTicker={event.bigTicker} /> :*/}
            {/*                        <div>*/}
            {/*                            [PLACEHOLDAH!!]*/}
            {/*                        </div>*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*                <div className="col-span-full md:col-span-1 md:pl-6">*/}
            {/*                    <MyStandings eventId={event.id} />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        {event.registration && (event.registration.totals?.miles || 0) > 0 &&*/}
            {/*            <div>*/}
            {/*                <EventStandings eventId={event.id} orgId={event.registration?.orgId} orgName={event.registration?.orgName} groupId={event.registration?.groupId} groupName={event.registration?.groupName} />*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*        {event.allowRegistration === 1 && !event.registration &&*/}
            {/*            <div className="mt-4 pt-4 border-0 border-solid border-t border-graylight flex justify-center">*/}
            {/*                <Button color="primary" onClick={() => onRegisterClick(event.slug)} fullWidth>*/}
            {/*                    <EventAvailableIcon className="mr-1" />Register for {event.name}*/}
            {/*                </Button>*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            <HCDialog
                open={showEdit}
                title={`Edit ${event.name} Registration`}
                actions={[
                    {label: 'Cancel', eventName: "dashboard_edit_registration_dialog_cancel_click", onClick: () => setShowEdit(false)},
                    {label: 'Save', eventName: "dashboard_edit_registration_dialog_save_click", color: 'primary', onClick: () => {
                        setShowEdit(false);
                        submitRef.current?.click();
                    }}
                ]}
                onClose={() => setShowEdit(false)}>
                <div className="w-[250px] md:min-w-[400px]">
                    <Formik
                        initialValues={{
                            ...event.registration
                        }}
                        onSubmit={onUpdateRegistration}
                    >
                        {({ values, setFieldValue }) => {
                            return (
                                <Form className="py-2">
                                    <input type="Submit" className="hidden" ref={submitRef} />
                                    <div>
                                        {/* @ts-ignore */}
                                        <CustomFormikField
                                            CustomComponent={TextField}
                                            name="mileGoal"
                                            label="Mile Goal"
                                            placeholder="15"
                                            type="number"
                                            variant="standard"
                                            fullWidth />
                                    </div>
                                    <div className="my-4">
                                        {/* @ts-ignore */}
                                        <CustomFormikField
                                            CustomComponent={OrganizationAutocomplete}
                                            name="orgId"
                                            variant="standard"
                                            // @ts-ignore
                                            groupAccountChecked={values.groupAccount === 1}
                                            onGroupAccountChange={(checked: boolean) => setFieldValue('groupAccount', checked ? 1 : 0, false)}/>
                                    </div>
                                    <div>
                                        {/* @ts-ignore */}
                                        <CustomFormikField
                                            CustomComponent={GroupSelect}
                                            name="groupId"
                                            orgId={values.orgId || ""}
                                            variant="standard" />
                                    </div>
                                    {/* @ts-ignore */}
                                    {event?.registration?.shirtSize &&
                                        <div className="mt-4">
                                            {/* @ts-ignore */}
                                            <CustomFormikField
                                                CustomComponent={ShirtSizeSelect}
                                                name="shirtSize"
                                                variant="standard" />
                                        </div>
                                    }
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </HCDialog>
            <LogActivityDialog open={showLogActivity} onClose={() => setShowLogActivity(false)} />
        </>
    )
};

export default EventItem;
