import React, { useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {TextField, Checkbox, FormControlLabel, Tooltip} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useSnackbar} from "notistack";

import {fetchOrganizations} from "../../../../redux/events";
import {organizationsSelector} from "../../../../redux/events/selectors";

interface Props {
    name: string;
    value: string;
    onChange: (event: any) => void;
    variant: string;
    groupAccountChecked: boolean;
    onGroupAccountChange: (checked: boolean) => void;
}

const OrganizationAutocomplete: React.FC<Props> = ({name, value, onChange, variant, groupAccountChecked, onGroupAccountChange}) => {
    const orgs = useSelector(organizationsSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!orgs) {
            // @ts-ignore
            dispatch(fetchOrganizations(enqueueSnackbar));
        }
    }, [orgs, dispatch, enqueueSnackbar]);

    const orgMap = useMemo(() => {
        if (orgs) {
            return orgs.reduce((ret, org) => ({
                ...ret,
                [org.id]: org
            }), {});
        }
        return {};
    }, [orgs]);

    const selectedOrg = orgMap[value];

    return (
        <div>
            <Autocomplete
                value={value}
                onChange={(event: any, newValue: any | null) => {
                    onChange({target: { name, value: newValue ? newValue.id.toString() : null }});
                }}
                // inputValue={inputValue}
                // onInputChange={(event, newInputValue) => {
                //     setInputValue(newInputValue);
                // }}
                loading={!orgs || orgs.length === 0}
                id="organization-autocomplete"
                options={orgs || []}
                getOptionLabel={option => {
                    // console.log("getOptionLabel", option);
                    if (typeof option === "string") {
                        return orgMap[option]?.name || "Choose Organization (Optional)"
                    }
                    return option.name;
                }}
                isOptionEqualToValue={(option, value) => option.id.toString() === value}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Organization (Optional)" variant="standard" />}
                renderOption={(props, option) => {
                    // console.log("render option", option);
                    return (
                        <li {...props} key={`org-${option.id}`} className="px-3 pb-1 cursor-pointer">
                            {/* @ts-ignore */}
                            {option.name}
                        </li>
                    )
                }}
            />
            {selectedOrg?.display_group_register_option === 1 &&
                <div>
                    <FormControlLabel control={<Checkbox checked={groupAccountChecked} onChange={e => onGroupAccountChange(e.target.checked)} />} label={
                        <Tooltip enterTouchDelay={0} title="Check this box to enter activity data for multiple people who do NOT have individual accounts. For example, Mrs. McBride’s 1st grade class, to enter all students’ activity data. It is recommended teacher/administer have a separate personal account.">
                            <div className="border-dotted border-0 border-b border-graydark cursor-help">Option for an elementary class</div>
                        </Tooltip>
                    } />
                </div>
            }
        </div>
    )
};

export default OrganizationAutocomplete;
