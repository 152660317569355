import {useEffect} from "react";
import * as Sentry from "@sentry/react";

export default function useSetAppContext() {
    useEffect(() => {
        Sentry.setContext("application", {
            version: process.env.REACT_APP_VERSION
        })
    }, []);
}
