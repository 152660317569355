import { useState } from 'react';

import LoginForm from "./loginForm";
import ForgotPassword from "./forgotPassword";

const Login = () => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    return (
        <div>
            {!showForgotPassword ?
                <LoginForm setShowForgotPassword={setShowForgotPassword} /> :
                <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
            }
        </div>
    )
};

export default Login;