import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import {CircularProgress, IconButton} from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBack";
import TrackableButton, { Props as ButtonProps } from "../buttons/trackable";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ActionButton extends ButtonProps {
    label: string;
    loading?: boolean;
}

export interface Props {
    open: boolean;
    title: any;
    actions: ActionButton[];
    onClose: () => void;
    children?: React.ReactNode;
    fullScreen?: boolean;
}

const HCDialog: React.FC<Props> = ({open, actions, title, onClose, fullScreen = false, children}) => {
    return (
        <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullScreen={fullScreen} maxWidth="xl">
            {fullScreen &&
                <AppBar position="sticky">
                    <Toolbar disableGutters>
                        <div className="mx-2">
                            <IconButton onClick={onClose}>
                                <BackIcon htmlColor="#000000" />
                            </IconButton>
                        </div>
                        <div className="flex-1 font-hc text-lg tracking-wider text-black">
                            {title}
                        </div>
                    </Toolbar>
                </AppBar>
            }
            {!fullScreen && (typeof title == "string" ?
                <DialogTitle>{title}</DialogTitle> :
                title)
            }
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                {actions.map(({ label, onClick, variant = "text", color = "inherit", loading = false, ...other }) => (
                    !loading ?
                        <TrackableButton key={`${title}-action-${label}`}
                            onClick={onClick}
                            variant={variant}
                            color={color}
                            {...other} >
                            {label}
                        </TrackableButton> :
                        <div className="w-12">
                            <CircularProgress size={30} />
                        </div>
                ))}
            </DialogActions>
        </Dialog>
    )
}

export default HCDialog;
