import React, {useRef, useState} from 'react';
import {Card, CardActions, CardContent, CircularProgress, TextField} from "@mui/material";
import {object, string} from "yup";
import {useDispatch, useSelector} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import {submitFeedback} from "../../redux/events";
import {submittingFeedbackSelector} from "../../redux/events/selectors";
import {useSnackbar} from "notistack";
import TrackableButton from "../_common/buttons/trackable";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {claimsSelector} from "../../redux/login/selectors";

const Feedback: React.FC = () => {
    const [showReceipt, setShowReceipt] = useState(false);
    const [verified, setVerified] = useState(false);
    const submitRef = useRef<HTMLInputElement>(null);
    const submittingFeedback = useSelector(submittingFeedbackSelector);
    const claims = useSelector(claimsSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: claims.email,
            message: "",
        },
        resolver: yupResolver(object().shape({
            email: string().email().required("Please enter a valid email."),
            message: string().required("Please enter a message.")
        }))
    });

    const onSubmit = (data: any) => {
        // @ts-ignore
        dispatch(submitFeedback(data.email, data.message, () => setShowReceipt(true), enqueueSnackbar));
    }

    return (
        <div>
            <div className="uppercase font-bold text-sm mb-2">
                Leave us your feedback
            </div>
            <Card>
                <CardContent>
                    {showReceipt ?
                        <div>
                            Your feedback is greatly appreciated!
                        </div> :
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="submit" ref={submitRef} className="hidden" />
                            <div>
                                {!claims?.email &&
                                    <div className="mb-2">
                                        <TextField
                                            {...register("email")}
                                            label="Email"
                                            placeholder="john.smith@gmail.com"
                                            variant="standard"
                                            error={Boolean(errors.email?.type)}
                                            helperText={errors.email?.message?.toString()}
                                            type="email"
                                            fullWidth
                                            multiline />
                                    </div>
                                }
                                <div>
                                    <TextField
                                        {...register("message")}
                                        label="Message"
                                        placeholder="We appreciate your feedback!"
                                        variant="standard"
                                        error={Boolean(errors.message?.type)}
                                        helperText={errors.message?.message?.toString()}
                                        fullWidth
                                        multiline />
                                </div>
                                <div className="mt-6">
                                    <div>
                                        <ReCAPTCHA
                                            sitekey="6LcIXtUiAAAAAJ41V6di6j9q0wlL3U8KepuytGBz"
                                            onChange={() => setVerified(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </CardContent>
                {!showReceipt &&
                    <CardActions>
                        <div>
                            {submittingFeedback ?
                                <CircularProgress size={30} /> :
                                <TrackableButton eventName="submit_feedback_click" type="submit" disabled={!verified} onClick={() => submitRef.current?.click()}>Submit</TrackableButton>
                            }
                        </div>
                    </CardActions>
                }
            </Card>
        </div>
    )
};

export default Feedback;
